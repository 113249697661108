/* image map widget */

div {
  &.image-map {
    position: relative;
  }
  &.image-area a {
    display: block;
    .box-sizing(border-box);
    position: absolute;
    text-decoration: none;
    outline: 0 none;
    border-color: black;
    &:hover,
    &:focus {
      border-width: 2px;
      border-style: dotted;
      border-color: lightgrey;
    }
    &.selected {
      border-color: var(--color-plum-1100);
      border-width: 2px;
      border-style: solid;
    }
    span {
      display: none;
    }
    &:hover span {
      text-indent: 0;
      vertical-align: top;
      color: black;
      background-color: #f4f4f4;
      font-weight: bold;
      position: absolute;
      margin: 0;
      bottom: 100%;
      padding: 5px;
      width: 75%;
    }
    &.selected {
      span {
        text-indent: 0;
        vertical-align: top;
        color: black;
        background-color: #f4f4f4;
        font-weight: bold;
        position: absolute;
        margin: 0;
        bottom: 100%;
        padding: 5px;
        width: 75%;
      }
      &.inactive {
        cursor: default;
      }
    }
  }
}

/* start image-highlight & text-highlight */
.image-highlight .image-highlight-markers,
.text-highlight .text-highlight-markers {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  border-bottom: 0;
  width: 100%;
}
.image-highlight label,
.text-highlight label {
  input {
    margin-top: 0;
  }
  span {
    position: relative;
    top: 3px;
    left: 3px;
  }
}
.image-highlight-widget,
.image-highlight-marker,
.image-highlight-board,
.image-highlight-shape,
.text-highlight-widget,
.text-highlight-marker {
  display: inline-block;
  *display: inline;
  zoom: 1;
}
.image-highlight-widget,
.image-highlight-widget .dk-input,
.image-highlight-board,
.text-highlight-widget,
.text-highlight-widget .dk-input,
.text-highlight-board {
  position: relative;

  div.uniform-checker {
    margin-left: 0;

    > span {
      left: 0;
      top: 0;
    }
  }
  div.uniform-focus {
    > span {
      left: -1px;
      top: -1px;
    }
  }
}
.text-highlight-widget .dk-input {
  margin-top: @spaceS;
}
.image-highlight-widget,
.text-highlight-widget {
  margin: 0 auto 0 auto;
  text-align: center;
}
.image-highlight-widget.image-highlight-block,
.text-highlight-widget.text-highlight-block {
  display: block;
}
.image-highlight-markers,
.text-highlight-markers {
  .border-radius(2px);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-style: solid;
  border-width: 1px;
  margin: 0;
  padding: 0;
  text-align: center;
}
.image-highlight-markers-disabled .image-highlight-marker,
.image-highlight-marker.image-highlight-marker-disabled,
.image-highlight-marker.image-highlight-selected.image-highlight-marker-disabled,
.text-highlight-markers-disabled .text-highlight-marker,
.text-highlight-marker.text-highlight-marker-disabled,
.text-highlight-marker.text-highlight-selected.text-highlight-marker-disabled {
  opacity: 0.65;
  cursor: not-allowed;
}
.image-highlight-marker,
.text-highlight-marker {
  background: var(--color-white);
  border: 1px solid var(--color-stonegrey-1000);
  color: var(--color-stonegrey-1000);
  cursor: hand;
  cursor: pointer;
  list-style-position: outside;
  list-style-type: none;
  margin: 8px 4px;
  padding: 12px 21px;

  &:first-child {
    margin-top: 16px;
  }
  &:last-child {
    margin-bottom: 16px;
  }
}
.image-highlight-marker.image-highlight-selected,
.text-highlight-marker.text-highlight-selected {
  cursor: default;
}
.image-highlight-marker-caption,
.text-highlight-marker-caption {
  display: inline;
  margin: 0;
}
.image-highlight-marker-icon,
.text-highlight-marker-icon {
  display: inline-block;
  position: relative;
  top: 3px;

  svg {
    fill: var(--color-stonegrey-1000);

    line {
      stroke: var(--color-stonegrey-1000);
    }
  }
}
.image-highlight-marker-dot,
.text-highlight-marker-dot {
  display: none;
}
.image-highlight-board {
  width: 100%;
  height: auto;
  border: 1px solid var(--color-stonegrey-900);
  border-top: none;
  .border-radius(2px);
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  cursor: crosshair;
  cursor: url('../../img/crop.png'), auto;
  cursor: url('../../img/crop.ico');
  *cursor: crosshair;
  cursor: crosshair\9;
  overflow: hidden;
  z-index: 1;
}
.text-highlight-board {
  width: 100%;
  height: auto;
  border: 1px solid #9f9f9f;
  overflow: hidden;
  z-index: 1;
  padding: 20px 0;
}
.image-highlight-board > img {
  width: 100%;
  max-width: none;
  border: 0;
}
.text-highlight-board > .inner {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
}
.text-highlight-board span {
  display: inline;
  padding-right: 0;
  padding-left: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
html[dir='rtl'] .text-highlight-board > .inner {
  text-align: right;
}
/* IE11 needs this media query hack to display the crosshair cursor */
/* otherwise, no cursor displays */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .image-highlight-board {
    cursor: crosshair;
  }
}
.image-highlight-shape {
  cursor: move;
  position: absolute;
  z-index: 1;

  .mask {
    content: '';
    display: inline-block;
    filter: alpha(opacity=25);
    opacity: 0.25;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
  }

  &.image-highlight-active {
    .mask {
      filter: alpha(opacity=40);
      opacity: 0.4;
    }
  }
}
.image-highlight-shape-remove {
  cursor: pointer;
  cursor: hand;
  position: absolute;
  right: 10px;
  top: 10px;
  height: 24px;
  z-index: 1001;
  padding: 0;

  svg {
    width: 24px;
    height: 24px;
  }

  @media (@mediaSlim) {
    height: 36px;
    svg {
      width: 36px;
      height: 36px;
      padding: 4px;
    }
  }

  &:focus {
    box-shadow: 0 0 0 2px var(--color-stonegrey-1200);
    outline: none;
  }
}
.image-highlight-markers,
.text-highlight-markers {
  border-color: var(--color-stonegrey-900);
}
.image-highlight-marker,
.text-highlight-marker {
  .highlight-btn-border-color(var( --color-stonegrey-1000));
  background: var(--color-white);
  .border-radius(24px);
  font-size: 18px;
  font-weight: 600;
  min-width: 152px;

  &:hover {
    .highlight-btn-color(var(--color-stonegrey-1100));
    color: var(--color-white);
  }
  &:focus:not(:active) {
    box-shadow: 0 0 0 4px var(--color-blueberry-300);
  }
}
.image-highlight-selected,
.text-highlight-selected {
  background: var(--color-stonegrey-1000);
  color: var(--color-white);
  svg {
    fill: var(--color-white);

    line {
      stroke: var(--color-white);
    }
  }
}

//
// Start Highlight marker default colors (up to 9)
//

/* Mixin for below */
.highlight-btn-color(@bgColor, @textColor: #fff) {
  background: @bgColor;
  color: @textColor;
  border: 1px solid @bgColor;

  svg {
    fill: @textColor;

    line {
      stroke: @textColor;
    }

    path {
      fill: @textColor;
    }
  }
}

.highlight-btn-border-color(@bgColor) {
  border: 1px solid @bgColor;
  color: @bgColor;

  svg {
    fill: @bgColor;

    line {
      stroke: @bgColor;
    }

    path {
      fill: @bgColor;
    }
  }
}

// Start Default 9 Color Pattern
// 0: green, 1: blue, 2: red, 3: gray, 4: purple, 5: brown, 6: orange, 7: teal, 8: yellow
.image-highlight-marker-c0,
.text-highlight-marker-c0 {
  html.legacy-colors & {
    .highlight-btn-border-color(var(--color-green-1100));
  }
  html:not(.legacy-colors) & {
    .highlight-btn-border-color(var(--color-green-1100));
  }

  &.image-highlight-selected,
  &.text-highlight-selected {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-green-1100));
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-green-1100));
    }
  }

  &:hover {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-green-1000));
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-green-1000));
    }
  }
}

.image-highlight-marker-c1,
.text-highlight-marker-c1 {
  html.legacy-colors & {
    .highlight-btn-border-color(var(--color-blue-1100));
  }
  html:not(.legacy-colors) & {
    .highlight-btn-border-color(var(--color-blueberry-1000));
  }

  &.image-highlight-selected,
  &.text-highlight-selected {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-blue-1100));
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-blueberry-1000));
    }
  }

  &:hover {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-blue-1000));
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-blueberry-1100));
    }
  }
}

.image-highlight-marker-c2,
.text-highlight-marker-c2 {
  html.legacy-colors & {
    .highlight-btn-border-color(var(--color-red-1100));
  }
  html:not(.legacy-colors) & {
    .highlight-btn-border-color(var(--color-grapefruit-1000));
  }

  &.image-highlight-selected,
  &.text-highlight-selected {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-red-1100));
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-grapefruit-1000));
    }
  }

  &:hover {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-red-1000));
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-grapefruit-1100));
    }
  }
}

.image-highlight-marker-c3,
.text-highlight-marker-c3 {
  html.legacy-colors & {
    .highlight-btn-border-color(var(--color-stonegrey-1000));
  }
  html:not(.legacy-colors) & {
    .highlight-btn-border-color(var(--color-stonegrey-1000));
  }

  &.image-highlight-selected,
  &.text-highlight-selected {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-stonegrey-1000));
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-stonegrey-1000));
    }
  }

  &:hover {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-stonegrey-1100));
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-stonegrey-1100));
    }
  }
}

.image-highlight-marker-c4,
.text-highlight-marker-c4 {
  html.legacy-colors & {
    .highlight-btn-border-color(var(--color-purple-1100));
  }
  html:not(.legacy-colors) & {
    .highlight-btn-border-color(var(--color-plum-900));
  }

  &.image-highlight-selected,
  &.text-highlight-selected {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-purple-1100));
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-plum-900));
    }
  }

  &:hover {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-purple-1000));
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-plum-1000));
    }
  }
}

.image-highlight-marker-c5,
.text-highlight-marker-c5 {
  html.legacy-colors & {
    .highlight-btn-border-color(var(--color-brown-1100));
  }
  html:not(.legacy-colors) & {
    .highlight-btn-border-color(var(--color-brown-1000));
  }

  &.image-highlight-selected,
  &.text-highlight-selected {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-brown-1100));
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-brown-1000));
    }
  }

  &:hover {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-brown-1000));
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-brown-1100));
    }
  }
}

.image-highlight-marker-c6,
.text-highlight-marker-c6 {
  html.legacy-colors & {
    .highlight-btn-border-color(var(--color-orange-1100));
  }
  html:not(.legacy-colors) & {
    .highlight-btn-border-color(var(--color-orange-1000));
  }

  &.image-highlight-selected,
  &.text-highlight-selected {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-orange-1100), var(--color-black));
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-orange-1000), var(--color-black));
    }
  }

  &:hover {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-orange-1000), var(--color-black));
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-orange-1100), var(--color-black));
    }
  }
}

.image-highlight-marker-c7,
.text-highlight-marker-c7 {
  html.legacy-colors & {
    .highlight-btn-border-color(var(--color-teal-1100));
  }
  html:not(.legacy-colors) & {
    .highlight-btn-border-color(var(--color-avocado-900));
  }

  &.image-highlight-selected,
  &.text-highlight-selected {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-teal-1100));
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-avocado-900));
    }
  }

  &:hover {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-teal-1000));
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-avocado-1000));
    }
  }
}

.image-highlight-marker-c8,
.text-highlight-marker-c8 {
  html.legacy-colors & {
    .highlight-btn-border-color(var(--color-yellow-1100));
  }
  html:not(.legacy-colors) & {
    .highlight-btn-border-color(var(--color-yellow-1000));
  }

  &.image-highlight-selected,
  &.text-highlight-selected {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-yellow-1100), var(--color-black));
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-yellow-1000), var(--color-black));
    }
  }

  &:hover {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-yellow-1000), var(--color-black));
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-yellow-1100), var(--color-black));
    }
  }
}
// End Default 9 Color Pattern

//
// Start Supported User-Defined Colors (legacy/rebrand)
//

// Green
.image-highlight-marker-green,
.text-highlight-marker-green {
  html.legacy-colors & {
    .highlight-btn-border-color(var(--color-green-1100)) !important;
  }
  html:not(.legacy-colors) & {
    .highlight-btn-border-color(var(--color-green-1100)) !important;
  }

  &.image-highlight-selected,
  &.text-highlight-selected {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-green-1100)) !important;
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-green-1100)) !important;
    }
  }

  &:hover {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-green-1000)) !important;
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-green-1000)) !important;
    }
  }
}

// Blue
.image-highlight-marker-blue,
.text-highlight-marker-blue {
  html.legacy-colors & {
    .highlight-btn-border-color(var(--color-blue-1100)) !important;
  }
  html:not(.legacy-colors) & {
    .highlight-btn-border-color(var(--color-blueberry-1000)) !important;
  }

  &.image-highlight-selected,
  &.text-highlight-selected {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-blue-1100)) !important;
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-blueberry-1000)) !important;
    }
  }

  &:hover {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-blue-1000)) !important;
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-blueberry-1100)) !important;
    }
  }
}

// Red
.image-highlight-marker-red,
.text-highlight-marker-red {
  html.legacy-colors & {
    .highlight-btn-border-color(var(--color-red-1100)) !important;
  }
  html:not(.legacy-colors) & {
    .highlight-btn-border-color(var(--color-grapefruit-1000)) !important;
  }

  &.image-highlight-selected,
  &.text-highlight-selected {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-red-1100)) !important;
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-grapefruit-1000)) !important;
    }
  }

  &:hover {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-red-1000)) !important;
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-grapefruit-1100)) !important;
    }
  }
}

// Gray
.image-highlight-marker-gray,
.text-highlight-marker-gray {
  html.legacy-colors & {
    .highlight-btn-border-color(var(--color-stonegrey-1000)) !important;
  }
  html:not(.legacy-colors) & {
    .highlight-btn-border-color(var(--color-stonegrey-1000)) !important;
  }

  &.image-highlight-selected,
  &.text-highlight-selected {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-stonegrey-1000)) !important;
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-stonegrey-1000)) !important;
    }
  }

  &:hover {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-stonegrey-1100)) !important;
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-stonegrey-1100)) !important;
    }
  }
}

// Purple
.image-highlight-marker-purple,
.text-highlight-marker-purple {
  html.legacy-colors & {
    .highlight-btn-border-color(var(--color-purple-1100)) !important;
  }
  html:not(.legacy-colors) & {
    .highlight-btn-border-color(var(--color-plum-1100)) !important;
  }

  &.image-highlight-selected,
  &.text-highlight-selected {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-purple-1100)) !important;
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-plum-1100)) !important;
    }
  }

  &:hover {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-purple-1000)) !important;
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-plum-900)) !important;
    }
  }
}

// Brown
.image-highlight-marker-brown,
.text-highlight-marker-brown {
  html.legacy-colors & {
    .highlight-btn-border-color(var(--color-brown-1100)) !important;
  }
  html:not(.legacy-colors) & {
    .highlight-btn-border-color(var(--color-brown-1000)) !important;
  }

  &.image-highlight-selected,
  &.text-highlight-selected {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-brown-1100)) !important;
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-brown-1000)) !important;
    }
  }

  &:hover {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-brown-1000)) !important;
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-brown-1100)) !important;
    }
  }
}

// Orange
.image-highlight-marker-orange,
.text-highlight-marker-orange {
  html.legacy-colors & {
    .highlight-btn-border-color(var(--color-orange-1100)) !important;
  }
  html:not(.legacy-colors) & {
    .highlight-btn-border-color(var(--color-orange-1000)) !important;
  }

  &.image-highlight-selected,
  &.text-highlight-selected {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-orange-1100), var(--color-black)) !important;
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-orange-1000), var(--color-black)) !important;
    }
  }

  &:hover {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-orange-1000), var(--color-black)) !important;
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-orange-1100), var(--color-black)) !important;
    }
  }
}

// Teal
.image-highlight-marker-teal,
.text-highlight-marker-teal {
  html.legacy-colors & {
    .highlight-btn-border-color(var(--color-teal-1100)) !important;
  }
  html:not(.legacy-colors) & {
    .highlight-btn-border-color(var(--color-avocado-900)) !important;
  }

  &.image-highlight-selected,
  &.text-highlight-selected {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-teal-1100)) !important;
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-avocado-900)) !important;
    }
  }

  &:hover {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-teal-1000)) !important;
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-avocado-1000)) !important;
    }
  }
}

// Yellow
.image-highlight-marker-yellow,
.text-highlight-marker-yellow {
  html.legacy-colors & {
    .highlight-btn-border-color(var(--color-yellow-1100)) !important;
  }
  html:not(.legacy-colors) & {
    .highlight-btn-border-color(var(--color-yellow-1000)) !important;
  }

  &.image-highlight-selected,
  &.text-highlight-selected {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-yellow-1100), var(--color-black)) !important;
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-yellow-1000), var(--color-black)) !important;
    }
  }

  &:hover {
    html.legacy-colors & {
      .highlight-btn-color(var(--color-yellow-1000), var(--color-black)) !important;
    }
    html:not(.legacy-colors) & {
      .highlight-btn-color(var(--color-yellow-1100), var(--color-black)) !important;
    }
  }
}

// End Supported User-Defined Colors (legacy/rebrand)

//
// End Highlight marker default colors
//

.ui-icon {
  width: 16px;
  height: 16px;
  background: none;
}
.ui-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
}
.ui-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%;
}
.ui-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0;
}
.ui-resizable-se {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: 1px;
  bottom: 1px;
}
.ui-icon-gripsmall-diagonal-se {
  background-position: -64px -224px;
}
@media screen and (max-width: 768px) {
  .image-highlight label input,
  .text-highlight label input {
    margin-top: 4px;
  }
  .image-highlight-marker,
  .text-highlight-marker {
    width: 90%;
    padding: 12px 0;
    margin: 6px 0;
  }
  .image-highlight-marker:first-child,
  .text-highlight-marker:first-child {
    margin-top: 16px;
  }
  .image-highlight-marker:last-child,
  .text-highlight-marker:last-child {
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 480px) {
  .text-highlight-board {
    padding: 1em 0;
  }
  .text-highlight-board > .inner {
    text-align: left;
    padding-left: 1em;
    padding-right: 1em;
  }
  .text-highlight-board span {
    font-size: 0.9em;
    float: none;
    line-height: 1;
  }
  html[dir='rtl'] .text-highlight-board > .inner {
    text-align: right;
  }
}
/* end image-highlight & text-highlight */
