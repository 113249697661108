/* scale widget  section */

@scale-image-path: '../../img/widgets/scale/';

.grid-scale-label {
  margin: 0 7px;
}

.scale_table_container {
  margin-top: @spaceL;
}

table.scale_table {
  margin: 0 auto;
  border: none;
  border-collapse: collapse;

  tr {
    &.scale_dont_know {
      background: none;
      display: table-row;
    }
    // bootstrap conflict
    &[class*='span'] {
      float: none;
      min-height: auto;
      margin-left: auto;
    }
  }
  .scale-options {
    display: -webkit-flex; /* iOS8 */
    display: flex;
    justify-content: space-between;
    height: inherit;
  }
}

td {
  padding: 0;

  &.scale_cell_value {
    width: 38px;
    border: none;
    border-collapse: collapse;
    font-weight: bold;
    vertical-align: middle;
    height: 60px;
    cursor: pointer;
    text-align: center;
  }
}

.scale_dont_know td {
  text-align: center;
  input {
    margin: 0 0;
  }
}

.scale_button {
  &:focus:not(:active) {
    .focus();
  }
}

.scale_button,
.scale_button_high {
  height: auto;
  cursor: pointer;
  z-index: 0;
}

@dotSize: 20px;
.scale_button span,
.scale_button_high span {
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 500;
  padding-top: 0;
  width: @dotSize;
  height: @dotSize;
  line-height: calc(@dotSize + 2px); // accounts for font weight inconsistencies
  padding: @spaceS;
  background-color: #fff;
  margin: auto;
  border: 1px solid var(--color-stonegrey-1100);
  .border-radius(50%);

  &:hover {
    background-color: var(--color-stonegrey-400);
    border-color: var(--color-stonegrey-1100);
  }

  &:focus,
  &:active {
    .focus();
  }
}

.scale_button_high:not(.hidden-response) span {
  background-color: var(--color-stonegrey-1100);
  border-color: var(--color-stonegrey-1100);
  color: #fff;

  &:hover {
    background-color: var(--color-stonegrey-1100);
    border-color: var(--color-stonegrey-1100);
  }
}

.scale_button_leftHigh {
  background: url('@{scale-image-path}button_leftEnd_h.gif') no-repeat center;
}

.scale_button_rightHigh {
  background: url('@{scale-image-path}button_rightEnd_h.gif') no-repeat center;
}

.scale_button_leftEnd {
  background: url('@{scale-image-path}button_leftEnd.gif') no-repeat center;
}

.scale_button_rightEnd {
  background: url('@{scale-image-path}button_rightEnd.gif') no-repeat center;
}

.scale_table td[id*='orientation_content_'] {
  display: block;
  font-weight: 700;
  font-size: 14px;
  position: relative;
}

.scale-options {
  vertical-align: middle;
  width: auto;
  padding: 0;
  background-image: linear-gradient(
    var(--color-stonegrey-500) 100%,
    transparent 90%
  );
  background-size: 100% 8px;
  background-repeat: no-repeat;
  background-position: center center;
  .scale_button {
    &:first-child {
      background-image: linear-gradient(
        to right,
        transparent 0%,
        #fff 0%,
        #fff 50%,
        transparent 50%
      );
    }
    &:last-child {
      background-image: linear-gradient(
        to right,
        transparent 50%,
        transparent 50%,
        transparent 0%,
        #fff 0%
      );
    }
  }
}
html[dir='rtl'] {
  .scale-label-left {
    text-align: left;
  }
  .scale-label-right {
    text-align: right;
  }

  @media (max-width: 927px) {
    .scale-label-left {
      text-align: right;
      padding-right: 0;
      padding-left: @spaceS;
    }
    .scale-label-right {
      text-align: left;
      padding-left: 0;
      padding-right: @spaceS;
    }
  }

  .scale-options {
    .scale_button {
      &:last-child {
        background-image: linear-gradient(
          to right,
          transparent 0%,
          #fff 0%,
          #fff 50%,
          transparent 50%
        );
      }
      &:first-child {
        background-image: linear-gradient(
          to right,
          transparent 50%,
          transparent 50%,
          transparent 0%,
          #fff 0%
        );
      }
    }
  }
}

@media (min-width: 928px) {
  .scale_table {
    max-width: 940px;
    width: 100%;
  }
  .scale-options {
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
}

// grid

.scale-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 35px;

  .scale-label-left,
  .scale-label-right {
    width: 100%;
    max-width: 150px;
    font-weight: 500;
  }

  .scale-label-left {
    text-align: right;
  }

  .scale-label-right {
    text-align: left;
  }

  .scale-options {
    flex-grow: 1;
    margin-left: @spaceM;
    margin-right: @spaceM;

    @media (max-width: @breakpoint-md) {
      .scale_button span,
      .scale_button_high span {
        width: 12px;
        height: 12px;
      }
    }
  }

  @media (max-width: 927px) {
    .scale-label-left,
    .scale-label-right {
      max-width: 100%;
      width: ~'calc(50% - @{spaceS})';
    }

    .scale-label-left {
      order: 1;
      text-align: left;
      padding-right: @spaceS;
    }

    .scale-label-right {
      order: 2;
      text-align: right;
      padding-left: @spaceS;
    }

    .scale-options {
      order: 3;
      width: 100%;
      margin-top: @spaceM;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
