@rank-image-path: '../../img/widgets/rank/';
@icon-width: 40px;
@rank-min-height: 31px;
@rank-small-border-radius: 4px;
@rank-font-size: 12px;
@rank-number-gap: 30px;

.question-rank {
  .ranking-instructions {
    .regular-inst {
      display: block;
    }
    .small-inst {
      display: none;
    }
    &.small {
      .regular-inst {
        display: none;
      }
      .small-inst {
        display: block;
      }
    }
  }
}

.rankPlace {
  position: relative;
  padding-left: @rank-number-gap;
  .number {
    font-weight: 700;
    padding-top: 5px;
    margin-right: 10px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.rankSelectionBlock {
  @bgColor: @ygLightGrey;
  @arrowWidth: 15px;
  @arrowHeight: 40px;

  .border-radius(2px);
  background-color: var(--color-stonegrey-300);

  position: relative;
  min-height: (@arrowHeight + 2 * @rank-small-border-radius);

  &:before {
    position: absolute;
    top: 50%;
    left: (-@arrowWidth);
    margin-top: (-@arrowHeight / 2);
    content: '';
    width: 0;
    height: 0;
    border-top: (@arrowHeight / 2) solid transparent;
    border-bottom: (@arrowHeight / 2) solid transparent;
    border-right: (@arrowWidth) solid var(--color-stonegrey-300);
  }
}

.rank-select {
  width: 100%;
}

.rankSelect-bg {
  background-color: var(--color-white);
  border: 1px dashed var(--color-stonegrey-900);
  width: calc(100% ~'-' @spaceM * 2 + 2);
  height: @rank-min-height;
  margin: @spaceM;
  position: absolute;
  top: 0;
  .border-radius(2px);
  z-index: 99;

  @media (@mediaSkinny) {
    display: none;
  }
}

#rankingWidget,
#rankingModal {
  .rank,
  .select {
    background-color: @white;
    border: 1px solid var(--color-stonegrey-900);
    min-height: calc(@rank-min-height ~'-' 1px);
    line-height: @rank-min-height;
    font-size: @baseFontSize;
    .border-radius(2px);
  }
  .rank {
    border-style: dashed;
    @media (@mediaSkinny) {
      // display: none;
    }
  }
  .select {
    cursor: move;
    height: auto;
    padding-left: @icon-width;
    @media (@mediaSkinny) {
      padding-left: (@rank-font-size / 2);
    }
  }
  .rankSelect {
    overflow: hidden;

    &.ui-draggable {
      overflow: visible;
    }
    &.addedToRank {
      overflow: visible;
      margin: -1px;
    }
  }
  // when rankSelect is dropped "into" rank
  .rank {
    &.drophover {
      background-color: var(--color-stonegrey-300);
      border: 1px solid var(--color-stonegrey-300);
    }
  }
  .cell-error {
    background-color: var(--color-grapefruit-300);
    border-color: var(--color-grapefruit-1000);
  }
  .dropdown-error {
    color: var(--color-grapefruit-1000);
    border-color: var(--color-grapefruit-1000);
  }
}

.rankSelect,
.rankPlace {
  margin: @spaceM;
  .text {
    color: #727272;
    font-size: @rank-font-size;
    display: block;
    line-height: @baseLineHeightFactor;
    padding: (@rank-font-size / 2) 0;
  }
  .icon {
    position: absolute;
    top: 0px;
    left: 0;
    &.add {
      width: @icon-width;
      height: 32px;
      button {
        display: inline-block;
        overflow: hidden;
        width: 100%;
        height: 100%;
        line-height: 32px;
        text-align: center;
        font-size: 15px;
        border: none;
        background: none;
        padding: 0;
        vertical-align: inherit;
        pointer-events: none;
        color: var(--color-stonegrey-1100);
        position: absolute;

        .drag-drop-icon {
          top: 6px;
          position: relative;

          svg {
            width: 20px;
            fill: var(--color-stonegrey-1100);
          }
        }
      }
      &:hover {
        background-position: 0 -32px;
      }
    }
    &.rem {
      float: right;
      margin: -1px -1px 0 10px;
      background: url('@{rank-image-path}rank_subt_bg.png') no-repeat top left;
      width: @icon-width;
      height: 32px;
      font-weight: bold;
      color: @white;
      //cursor: pointer
      span {
        display: inline-block;
        text-indent: -3000px;
        overflow: hidden;
        width: 100%;
        height: 100%;
      }
      &:hover {
        background-position: 0 -32px;
      }
    }
  }
}
.rankSelect {
  z-index: 100;
}

.rank-dk {
  margin-top: @spaceM;
  &-container {
    margin-left: @spaceM + @rank-number-gap;

    label {
      position: relative;

      div.uniform-checker {
        margin-left: 0;
      }

      .label-inner {
        padding-left: 20px;
      }
    }
  }
}

.lt-ie8.iexpl {
  .rankSelect,
  .rankPlace {
    clear: both;
    .icon {
      margin-top: -27px;
      &.add,
      &.rem {
        span {
          text-indent: 0;
        }
      }
    }
    .number {
      float: left;
    }
    .rank {
      float: left;
      clear: right;
    }
  }
}

#rankingModal {
  background-color: @white;
  .border-radius(2px);
  overflow: auto;
  position: fixed;

  @gap: 20px;
  top: @gap;
  left: @gap;
  bottom: @gap;
  right: @gap;

  z-index: 5000;
  h3 {
    margin: 5px auto;
  }
  .rankSelectionBlock {
    width: auto;
    margin-left: 0;
    background: none;
    .select {
      cursor: pointer;
    }
    &:before {
      content: none;
    }
  }
  display: none;
}

#rankingModalBG {
  position: fixed;
  z-index: 4000;
  width: 100%;
  height: 100%;
  .opacity(30);
  background-color: @black;
  top: 0;
  left: 0;
  display: none;
}

/* Skinny (mobile) View */
// Mixin
.skinny-mixin() {
  .rankSelect,
  .rankPlace {
    .helpText {
      color: lighten(@ygMidGrey, 10);
      font-style: italic;
      margin: 7px;
      font-size: @rank-font-size;
      line-height: @rank-min-height;
      padding-left: @icon-width;
    }
    .icon {
      &.add {
        span {
          cursor: pointer;
          background: url('@{rank-image-path}rank_add_plus.png') no-repeat
            center;
        }
      }
      &.rem {
        span {
          cursor: pointer;
          background: url('@{rank-image-path}rank_subt_minus.png') no-repeat
            center;
        }
      }
    }
  }
  .rankPlacementBlock {
    .rank {
      position: relative;
      cursor: pointer;
      > .icon {
        top: -1px;
        left: -1px;
      }
      &.hasRank {
        .icon,
        .helpText {
          display: none;
        }
      }
    }
  }
  .question-rank {
    .ranking-instructions {
      .small-inst {
        display: block;
      }
      .regular-inst {
        display: none;
      }
    }
  }
  .rankSelect {
    .select {
      #gradient > .vertical(lighten(@ygLightGrey, 10), darken(@ygLightGrey, 10));;
    }
  }
}
// mobile
@media (@mediaSkinny) {
  .skinny-mixin();
}
// desktop
#skinnyView {
  .skinny-mixin();
}
#rankingModal {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
#rankingWidget #skinnyView,
#rankingModal {
  .rankSelect {
    .select {
      #gradient > .vertical(lighten(@ygLightGrey, 10), darken(@ygLightGrey, 10));;
      padding-left: (@rank-font-size / 2);
    }
  }
}
/* End Skinny (mobile) View */

@media (min-width: 480px) and (max-width: 768px) {
  .widget {
    img {
      width: auto;
    }
  }
}

#rankingWidget {
  #rankPlacementBlockSkinny {
    .rankPlace {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 0;
      .number {
        position: unset;
        margin-right: 24px;
      }
      .rank-selections {
        flex: 1;
        overflow: hidden;
        .rank-dropdown {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 8px 8px 8px 16px;
          border: 1px solid #616e90;
          border-radius: 2px;
          background-color: #ffffff;
          cursor: pointer;
          div,
          span {
            color: #4a5878;
          }
          &.diabledDropdown {
            border: var(--color-stonegrey-500);
            background-color: var(--color-stonegrey-100);
          }
          div {
            flex: 1;
            max-height: 85px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          & > span {
            font-size: 30px;
            height: 32px;
            width: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-left: 8px;
            & > span {
              margin: 0 0 4px 0.5px;
            }
          }
          &.selected {
            cursor: default;
            pointer-events: none;
            & > span {
              background-color: #e6e8ed;
              cursor: pointer;
              pointer-events: all;
            }
            div {
              font-weight: 800;
              img {
                height: 53px;
                margin-right: 8px;
              }
            }
          }
        }
        .rank-dropdown-modal {
          .modal-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 24px 16px 24px 24px;
            .modal-title {
              font-weight: 500;
            }
            .rank-modal-close {
              width: 24px;
              height: 24px;
              font-size: 20px;
              background-color: #dde1e7;
              border-radius: 50%;
              border: none;
              overflow: hidden;
              position: relative;
              cursor: pointer;
              span {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: 24px;
                bottom: 1.5px;
                left: 0px;
              }
            }
          }
          .modal-body {
            padding-bottom: 8px;
            .rank-dropdown-option {
              min-height: 31px;
              padding: 8px 16px;
              cursor: pointer;
              display: flex;
              align-items: center;
              border-top: 1px solid #e6e8ed;
              input[type='radio'] {
                margin: 0;
                width: 18px;
                height: 18px;
                accent-color: #241d36;
                pointer-events: none;
              }
              label {
                margin-bottom: 0;
                margin-left: 16px;
                img {
                  height: 85px;
                  margin-right: 16px;
                }
              }
              &.selected {
                background-color: #e6e8ed;
                label {
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
  }
}
