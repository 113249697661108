// /*
//  *---------------------------------------------------------
//  *
//  *Title:    Global stylsheet
//  *Notes:    pop-up follow up windows - x2 IE hacks ideally need removing
//  *---------------------------------------------------------*/
//

// Hide images when printing
@media print {
  img {
    display: none !important;
  }
}

// Grey progress bar.
.progress-grey.progress-striped .bar,
.progress-striped .bar-grey {
  #gradient > .striped(@ygLightGrey);
}

.divider {
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  text-align: center;
  height: 20px;
  margin: 0px 20px 0px 20px;
  padding: 0;
  background: url(../img/divider.gif);
}

.modal-backdrop {
  background-color: #ccc;
}

label {
  display: inline-block;
}

.assistive-text {
  position: absolute;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.accessible-dropdown-trigger {
  position: relative;

  .accessible-dropdown {
    display: none;
    position: absolute;
    z-index: 7000;
    left: 50%;
    float: left;
    min-width: 6rem;
    max-width: 20rem;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
    border: 1px solid #dddbda;
    border-radius: 0.25rem;
    padding: 0.25rem 0;
    font-size: 0.75rem;
    background: #fff;
    -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);

    [role='menu'] {
      margin: 0;
      padding: 0;
    }

    [role='menuitem'] {
      list-style: none;
      padding: 0.5rem 0.75rem;
      font-weight: normal;

      &:focus,
      &:hover {
        outline: 0;
        text-decoration: none;
        background-color: var(--color-stonegrey-200);
      }
    }
  }

  &-open {
    .accessible-dropdown {
      display: block;
      visibility: visible;
      opacity: 1;
    }
  }
}

// TODO: needs to be updated
.error_message {
  border: 0px solid black;
  font-weight: bold;
  font-size: 120%;
  color: #e31b1d;
  margin: 5px 0px 15px 15px;
}

a,
img {
  outline-style: none;
}

div#container form {
  margin: 0px;
  padding: 0px;
}

.question-response-list {
  margin-top: @standardMargin;
}

.question-response-item {
  margin-bottom: @spaceXS;
}

.question-response-column {
  background-color: transparent;
  border: 0px solid black;
  float: left;
  width: 200pt;
}

.question-response-row {
  width: 100%;
}

.question-open-ended-input {
  @media (@mediaSkinny) {
    margin-top: @spaceL;
  }

  select {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: @breakpoint-sm) {
      min-width: 150px;
      max-width: 473px;
    }

    @media (max-width: @breakpoint-sm) {
      width: 100%;
    }
  }
}

.question-open-ended-main {
  text-align: left;
  margin-right: @inputPaddingAndBorder;

  label {
    margin-top: @spaceM;
    input {
      margin-right: 5px;
    }
  }

  textarea,
  input,
  .uneditable-input {
    width: auto;
    max-width: 100%;
    height: auto;

    @media (@mediaSkinny) {
      max-width: 95%;
    }
  }
  textarea:not([cols]) {
    width: 100%;
  }
}

.free-text {
  margin: 0 0 14px;
  clear: both;
  text-align: left;
  ul,
  ol {
    margin-left: 20px;
  }
  b {
    font-weight: 600;
  }
}

.soft-required {
  color: green;
}

.openend-subtext-cont {
  border: 0px;
}

.openend-subtext-message {
  text-align: left;
  font-size: 100%;
  color: #666666;
  padding-right: 0px;
}

.dropdown-subtext-cont {
  margin: 0px 4px 0px 0px;
}

.dropdown-subtext-message {
  text-align: left;
  font-size: 100%;
  color: #666666;
  padding-right: 8px;
}

div.clearit {
  clear: both;
}

.reg-name,
.reg-address {
  margin-bottom: 38px;
  margin-left: auto;
  margin-right: auto;
}

/* height and padding get adjusted in javascript */

div {
  &.popup-top {
    background: url(../img/widgets/popup/ivw-popup-top.gif) no-repeat top left;
    margin-left: -9px;
    padding-bottom: 10px;
    padding-top: 75px;
    text-indent: 0px;
    div {
      z-index: 101;
      &.question-response-column {
        padding-left: 30px;
        float: none;
      }
    }
    .question-response-item {
      margin-left: 17px;
      text-indent: 0px;
      text-align: left;
    }
    .error_message {
      margin-left: 30px;
    }
  }
  &.popup-bottom {
    background: url('../img/widgets/popup/ivw-popup-bottom.gif') no-repeat
      bottom left;
    height: 13px;
    margin-top: -5px;
  }
}

/* pop-up follow up windows - used them in ubsc project */

img.popup-close {
  float: right;
  margin-right: 30px;
  cursor: pointer;
}

*:first-child + html #main_cont div {
  &.popup-top {
    margin-left: 0px;
  }
  &.popup-bottom {
    margin-left: 9px;
  }
}

#overlay {
  visibility: hidden;
  position: absolute;
  z-index: 99;
  background-color: white;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  .opacity(50);
  height: 100%;
  min-height: 100%;
}

.response-label {
  width: 100%;

  &:focus,
  &.selected {
    background: var(--color-stonegrey-300);
    .border-radius(2px);
    outline: none;
  }

  @media (hover: hover) {
    &:hover {
      background: var(--color-stonegrey-300);
      .border-radius(2px);
      outline: none;
    }
  }

  &.selected {
    font-weight: 600;
  }

  &.hidden-response {
    background: initial;

    .label-text {
      font-weight: normal;
    }
  }
}

#reg-global {
  margin-left: 20px;
  width: 830px;
  td {
    &.label-holder {
      padding-bottom: 5px;
    }
    &.error-column {
      width: 50%;
    }
  }
}

#notification {
  display: none;
  h4 {
    margin: 0 0 0 0;
  }
}

#notifications {
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  .error {
    background: #e31b1d;
    padding: 25px 20px 20px 45px;
    position: relative;
    margin: 0;
    p {
      color: white;
      font-weight: bold;
      font-size: 120%;
      margin: 0 0 13px;
      padding-right: 50px;
      display: block;
    }
    .close {
      color: #ccc;
      position: absolute;
      right: 13px;
      top: 10px;
      padding: 5px;
      font-weight: bold;
      cursor: pointer;
      &:hover {
        color: yellow;
      }
    }
    .refresh {
      padding: 5px;
      cursor: pointer;
      &:hover {
        color: yellow;
      }
    }
  }
}

.alert {
  text-align: left;
}

input,
textarea,
select,
.uneditable-input {
  width: auto;
}

@media (@mediaSkinny) {
  body {
    background-color: @white;
  }
}
