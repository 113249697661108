/* Questions */

.question {
  margin-bottom: @standardMargin * 3;
  .question-text {
    color: var(--color-stonegrey-1200);
    margin-bottom: @spaceXL;
    font-size: @baseFontSize;
    line-height: 1.5;
    border: none;

    b {
      font-weight: 600;
    }
  }
  .instructions {
    margin-bottom: @standardMargin;
    color: var(--color-stonegrey-1200);
  }
  ul {
    vertical-align: top;
  }
}

textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'],
.uneditable-input {
  margin-bottom: 0px;
  color: var(--color-stonegrey-1200);

  font-size: @baseFontSize;
  padding: 10px 16px 12px;
  height: 26px; /* with padding, should end up at ~48px in height */

  border: 1px solid var(--color-stonegrey-900);
  .box-shadow(none);
  .border-radius(2px);

  &::placeholder {
    color: var(--color-stonegrey-900);
  }

  &:hover,
  &:focus:not(:active) {
    border-color: var(--color-stonegrey-1200);
  }
  &:focus:not(:active) {
    .box-shadow(0 0 0 4px var(--color-blueberry-300));

    &::placeholder {
      color: transparent;
    }
  }
}

.form-horizontal {
  .control-group {
    position: relative;
  }

  .control-label {
    padding-top: 0;

    @media (min-width: @breakpoint-sm) {
      .vertical-align(absolute, 48%);
    }
  }
}

.ui-widget {
  font-size: 1.1em;
}
.ui-widget .ui-widget {
  font-size: @baseFontSize;
}
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-size: @baseFontSize;
}
.ui-widget :active {
  outline: none;
}
