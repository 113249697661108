.unsupported-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  line-height: 28px;

  .title {
    font-weight: bold;
    margin-bottom: 16px;
  }

  .text {
    margin-bottom: 48px;
    max-width: 1020px;
  }

  .browser-list {
    display: flex;
    justify-content: center;
    width: 100%;

    .browser {
      border: 1px solid var(--color-stonegrey-800);
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      width: 170px;
      height: 144px;
      cursor: pointer;
      margin: 0 8px;

      .logo {
        margin: 24px auto 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 48px;
          height: 48px;
          flex-grow: 0;
        }
      }
      .row {
        margin: 24px 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .name {
          font-weight: bold;
          font-size: 14px;
          line-height: 24px;
          margin-right: 10px;
        }

        .icon {
          width: 12px;
          height: 12px;
          flex-grow: 0;
          object-fit: contain;
          background: url(../img/unsupported/icon-link.svg) no-repeat top center;
        }
      }
    }
  }

  .buttons {
    display: flex;
    width: 100%;
    margin-top: 96px;
    justify-content: center;
    align-items: center;
    gap: 16px;

    button {
      padding: 12px 32px;
      border-radius: 32px;
      border: solid 1px var(--color-stonegrey-1000);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      margin: 0 8px;

      .icon {
        width: 18px;
        height: 18px;
        flex-grow: 0;
        object-fit: contain;
      }

      &.copy-link {
        background-color: var(--color-stonegrey-1000);
        color: white;

        .icon {
          background: url(../img/unsupported/icon-copy.svg) no-repeat top center;
          margin-right: 12px;
        }
      }

      &.continue {
        color: var(--color-stonegrey-1000);
        background-color: white;

        .icon {
          background: url(../img/unsupported/icon-arrow.svg) no-repeat top
            center;
          margin-left: 12px;
        }
      }
    }
  }
}
