.question-address {
  @media (@mediaSkinny) {
    .question-text {
      margin-bottom: @spaceL;
    }
  }

  .form-horizontal,
  .form-horizontal-condensed,
  .form-horizontal-responsive {
    .control-group {
      &.error {
        .control-label {
          color: var(--color-stonegrey-1200);
        }
      }
    }
  }
}

.form-horizontal {
  .control-label {
    text-align: left;
    font-weight: 500;
    @media (min-width: @breakpoint-sm) {
      .vertical-align(relative, 0%);
    }
  }
}

.form-horizontal-condensed {
  .control-group {
    // Use standard form spacing as in vertical forms.
    margin-bottom: @baseLineHeight / 2;
  }
}

// Make .form-horizontal vertical for mobile, basically by undoing what it does.
.form-horizontal-responsive {
  @media (@mediaSkinny) {
    input,
    select {
      width: 100%;
      max-width: 95%;
    }
    select {
      max-width: 89%;
    }

    .controls {
      margin-left: 0;
      // To prevent input from extending too far
      margin-right: @inputPaddingAndBorder;
    }

    .control-label {
      float: none;
      width: auto;
      padding-top: 0;
      text-align: left;
      display: block;
    }
  }
}

.select-content-box {
  // Size like text inputs
  .box-sizing(content-box);
  // ...and use its natural height
  height: auto;
}

// Field-specific sizes
[name='street-address1'],
[name='street-address2'],
[name='street-address3'] {
  width: 350px;
}

[name='city'] {
  width: 350px;
}

[name='stateprovince'] {
  width: 327px;

  @media (@mediaSkinny) {
    width: 100%;
    padding: 4px 0px 4px 16px;
  }
}

[name='postalcode'] {
  width: 350px;
}

[name='country'] {
  width: 327px;

  @media (@mediaSkinny) {
    width: 100%;
    padding: 4px 0px 4px 16px;
  }
}

// LiveAddress plugin overrides
.smarty-suggestion {
  font-size: inherit;
}

.smarty-tag {
  display: none !important;
}
