[class$='-colorpicker'] {
  .question-response-list {
    .dynamic-row {
      display: flex;

      [class*='@{dynamicRowItemPrefix}-'] {
        flex: 1;
        margin-left: 5px;
        margin-right: 5px;
        display: table;
        width: 100%;
        margin-bottom: 16px;

        @media (@mediaSkinny) {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }

      [class*='@{dynamicRowItemPrefix}-']:first-child {
        margin-left: 8px;
      }

      [class*='@{dynamicRowItemPrefix}-']:last-child {
        margin-left: 5px;
        margin-right: 8px;
        overflow: visible;
      }

      @media (@mediaSkinny) {
        display: block;
      }

      .response-button {
        display: table-cell;
        vertical-align: middle;

        .border-radius(18px);

        font-size: 16px;
        padding: 4px 12px;
        margin: 0px;

        @media (@mediaSlim) {
          margin: 2px 8px 0px;
        }

        @media (@mediaSkinny) {
          font-size: 18px;
          padding: 8px 24px;
          margin: 2px 8px 0px;
          .border-radius(24px);
        }

        img {
          object-fit: scale-down;
        }
      }
    }
  }
}

.colorpicker {
  // Components/modifiers
  &-grid {
    border-collapse: separate;
    border-spacing: @l-gap;
    table-layout: fixed;
    @media (@mediaSkinny) {
      display: block;

      // Hack - really shouldn't be using tag names.
      tbody {
        display: block;
      }
    }

    &-row {
      @media (@mediaSkinny) {
        display: block;
      }
    }

    &-center {
      margin: 0 auto;
    }
  }

  // Overrides for colorpicker, which (for the moment) uses a table for layout.
  &-grid &-btn {
    display: table-cell;
    @media (@mediaSkinny) {
      display: table;
      table-layout: fixed;
      width: 100% !important;
      margin: @l-gap 0;
      .box-sizing(border-box);
    }

    &-label {
      @media (@mediaSkinny) {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}
