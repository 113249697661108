.registration-widget {
  input[type='checkbox'] {
    margin-top: -2px;
  }

  .registration-email-input {
    width: 91%;
    @media (min-width: 480px) {
      width: 360px;
    }
  }

  .terms-agree-wrap {
    margin-top: @xl-gap;
  }

  .join-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: @xl-gap;
    .btn-join {
      padding-left: 40px;
      padding-right: 40px;
      height: @spaceL * 2;
      min-width: 170px;
      background-color: var(--color-stonegrey-1000);
      border: 1px solid var(--color-stonegrey-1000);
      padding-top: 5px;
      font-weight: bold;
      color: #fff;
      line-height: normal;
      font-size: inherit;
      .border-radius(@buttonBorderRadius);
      .loading-mixin();
    }
  }
}
