// From jQuery UI's .ui-helper-hidden-accessible
.hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Override bootstrap's WebKit-specific blue focus ring
.tab-focus() {
  outline: thin dotted @black;
}

.kerning-lining-numerals-no-ligatures() {
  .font-feature-settings(@kern: 1; @liga: 0; @lnum: 1);
}

// Work around Chrome bug where setting font-features makes .hide-text not
// actually take up zero space.
.hide-accessible {
  &:extend(.hide-text);
  display: inline-block;
  width: 0;
}

#grid {
  .core {
    .span(@gridColumns) {
      width: (@gridColumnWidth * @gridColumns) +
        (@gridGutterWidth * (@gridColumns - 1));
    }
  }
}

.border-radius(@radiusTL, @radiusTR: @radiusTL, @radiusBR: @radiusTL, @radiusBL: @radiusTL) {
  -webkit-border-radius: @radiusTL @radiusTR @radiusBR @radiusBL;
  -moz-border-radius: @radiusTL @radiusTR @radiusBR @radiusBL;
  border-radius: @radiusTL @radiusTR @radiusBR @radiusBL;
}

.box-shadow(@shadow) {
  -webkit-box-shadow: @shadow;
  -moz-box-shadow: @shadow;
  box-shadow: @shadow;
}

.transform(@value) {
  -webkit-transform: @value;
  -moz-transform: @value;
  -o-transform: @value;
  -ms-transform: @value;
  transform: @value;
}

.vertical-align(@position: absolute, @top: 50%) {
  top: @top;
  position: @position;
  .transform(translateY(-@top));
}

.horizontal-align() {
  left: 50%;
  position: absolute;
  .transform(translateX(-50%));
}

.full-align() {
  top: 50%;
  left: 50%;
  position: absolute;
  .transform(translate(-50%, -50%));
}

// avoid texts/elements cursor selection
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.loading-mixin {
  .loader-button {
    display: none;
    position: relative;
    padding-left: 5px;
    padding-right: 5px;
    margin: auto;
    width: 18px;

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    .circular {
      animation: rotate 2s linear infinite;
      height: 100%;
      transform-origin: center center;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    .path {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      animation: dash 1.5s ease-in-out infinite;
      stroke-linecap: round;
      stroke: #fff;
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }

      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
      }

      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
      }
    }
  }

  &.loading-button {
    padding-left: @spaceXL;
    padding-right: @spaceXL;

    .ygicon-left,
    .ygicon-right,
    .button-text {
      display: none;
    }

    .loader-button {
      display: block;
    }
  }

  @media (@mediaSkinny) {
    margin-left: @spaceM;
    margin-right: 0;
    min-width: 0;

    &.loading-button {
      padding-left: 3px;
      padding-right: 3px;
      padding-top: 13px;
    }
  }

  &:disabled {
    background-color: var(--color-stonegrey-500);
    border-color: var(--color-stonegrey-500);
  }

  &:hover:enabled {
    background-color: var(--color-stonegrey-1100);
    border-color: var(--color-stonegrey-1100);
  }

  &:focus:not(:disabled),
  &:focus:not(:active) {
    .focus();
    background-color: var(--color-stonegrey-1100);
  }

  &:focus:active {
    .focus();
    background-color: var(--color-stonegrey-1200);
    border-color: var(--color-stonegrey-1200);
  }

  [class^='ygicon-'],
  [class*=' ygicon-'] {
    color: #fff;
    font-size: 18px;
  }
}
