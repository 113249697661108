.dk-input {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  div.uniform-checker {
    margin-left: 0px;
  }

  &.dk-align-left {
    justify-content: flex-start;
  }

  &.dk-align-right {
    justify-content: flex-end;
  }
}
