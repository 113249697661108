/* calendar widget */

.calendar {
  background: white url(../../img/widgets/calendar/calendar375.png) no-repeat
    top left;
  width: 375px;
  height: 375px;
  border: 0px solid black;
  padding: 20px 0px 0px 0px;
  margin-left: 240px;
}

.calendar-month {
  margin-top: 0px;
  height: 60px;
  width: 100%;
  text-align: center;
  h1 {
    color: #e31b1d;
    margin-top: 5px;
    margin-bottom: 20px;
  }
}

table.calendar-tab {
  td {
    height: 40px;
    width: 40px;
    text-align: center;
    padding: 0px 14px 0px 14px;
    border: 1px solid white;
  }
  tr {
    height: 40px;
  }
}

.calendar-week {
  height: 25px;
  width: 100%;
}

.calendar-body {
  width: 355px;
  height: 220px;
  padding-left: 0px;
}

.valid-day {
  font-weight: bold;
  cursor: pointer;
}

table.calendar-tab td.selected {
  background: url('../../img/widgets/calendar/calendarBox.png') no-repeat center;
}
