// for fontawesome icon which has alternative for IE7
.fa-no-ie7 {
  .lt-ie8 & {
    display: none;
  }
}

// for alternative icon for IE7
.fa-ie7 {
  display: none;

  .lt-ie8 & {
    display: inline-block;
  }
}

.fa-chevron-right {
  position: relative;
  z-index: 100000;
}

.icon-chevron-left.fa-ie7,
.icon-chevron-right.fa-ie7 {
  display: inline-block;
}
.fa-chevron-left.fa-no-ie7,
.fa-chevron-right.fa-no-ie7 {
  display: none;
}
