.dyngrid {
  &-phase {
    &-hidden {
      display: none;
    }
  }

  &-category-text {
    font-weight: bold;
    margin-bottom: @l-gap;
  }

  .dynamic-row {
    display: flex;
    margin-bottom: 16px;

    @media (@mediaSkinny) {
      width: 100%;
    }

    [class*='@{dynamicRowItemPrefix}-'] {
      flex: 1;
      margin-left: 5px;
      margin-right: 5px;

      @media (@mediaSkinny) {
        width: 100%;
        float: none;
        display: flex;
        margin: 0;
      }
    }

    [class*='@{dynamicRowItemPrefix}-']:first-child {
      margin-left: 8px;

      @media (@mediaSkinny) {
        margin-left: 0px;
      }
    }

    [class*='@{dynamicRowItemPrefix}-']:last-child {
      margin-left: 5px;
      margin-right: 8px;

      @media (@mediaSkinny) {
        margin: 0;
      }
    }
  }

  .response-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    word-break: break-word;

    .border-radius(18px);

    font-size: 16px;
    padding: 4px 12px;
    margin: 0;

    @media (@mediaSlim) {
      margin: 2px 8px 0px;
    }

    @media (@mediaSkinny) {
      font-size: 18px;
      padding: 8px 24px;
      margin: 2px 8px 0px;
      width: 100% !important; // force full-width on narrow devices
    }

    //Override color on transitions
    &:not(.hidden-response).response-button-brown.disabled {
      .legacy-colors & {
        background-color: var(--color-brown-1100);
        border-color: var(--color-brown-1100);
      }
      html:not(.legacy-colors) & {
        background-color: var(--color-brown-1000);
        border-color: var(--color-brown-1000);
      }
    }
    &:not(.hidden-response).response-button-green.disabled {
      .legacy-colors & {
        background-color: var(--color-green-1100);
        border-color: var(--color-green-1100);
      }
      html:not(.legacy-colors) & {
        background-color: var(--color-green-1100);
        border-color: var(--color-green-1100);
      }
    }
    &:not(.hidden-response).response-button-orange.disabled {
      .legacy-colors & {
        background-color: var(--color-orange-1100);
        border-color: var(--color-orange-1100);
      }
      html:not(.legacy-colors) & {
        background-color: var(--color-orange-1000);
        border-color: var(--color-orange-1000);
      }
    }
    &:not(.hidden-response).response-button-purple.disabled {
      .legacy-colors & {
        background-color: var(--color-purple-1000);
        border-color: var(--color-purple-1000);
      }
      html:not(.legacy-colors) & {
        background-color: var(--color-plum-900);
        border-color: var(--color-plum-900);
      }
    }
    &:not(.hidden-response).response-button-yellow.disabled {
      .legacy-colors & {
        background-color: var(--color-yellow-1100);
        border-color: var(--color-yellow-1100);
      }
      html:not(.legacy-colors) & {
        background-color: var(--color-yellow-1000);
        border-color: var(--color-yellow-1000);
      }
    }
    &:not(.hidden-response).response-button-blue.disabled {
      .legacy-colors & {
        background-color: var(--color-blue-1000);
        border-color: var(--color-blue-1000);
      }
      html:not(.legacy-colors) & {
        background-color: var(--color-blueberry-1000);
        border-color: var(--color-blueberry-1000);
      }
    }
    &:not(.hidden-response).response-button-red.disabled {
      .legacy-colors & {
        background-color: var(--color-red-1000);
        border-color: var(--color-red-1000);
      }
      html:not(.legacy-colors) & {
        background-color: var(--color-grapefruit-1000);
        border-color: var(--color-grapefruit-1000);
      }
    }
    &:not(.hidden-response).response-button-teal.disabled {
      .legacy-colors & {
        background-color: var(--color-teal-1000);
        border-color: var(--color-teal-1000);
      }
      html:not(.legacy-colors) & {
        background-color: var(--color-avocado-900);
        border-color: var(--color-avocado-900);
      }
    }
  }
}

.dyngrid-category-text {
  &.row-align-right {
    text-align: right;
  }

  &.row-align-left {
    text-align: left;
  }

  &.row-align-center {
    text-align: center;
  }
}
