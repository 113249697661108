.response-button {
  background-color: var(--color-white);
  border: 1px solid var(--color-stonegrey-1000);
  color: var(--color-stonegrey-1000);
  cursor: pointer;
  padding-top: @spaceS;
  padding-bottom: @spaceS;
  padding-left: @spaceL;
  padding-right: @spaceL;
  display: block;
  text-align: center;
  margin: 2px @spaceS @spaceM @spaceS;
  font-family: @GraphikWebfontName;
  font-weight: 600;
  font-size: 18px;
  .border-radius(24px);
  &:extend(.colorpicker-btn all);

  &.response-button {
    &:extend(.colorpicker-btn all);
  }

  &:hover {
    .hover();
  }

  &:focus,
  &:active {
    .focus();
  }

  .openend-inline input {
    width: 100%; // fit input within button element
  }
}
