.colorpicker-btn {
  .user-select(none);
  .transition(opacity @m-duration ease);

  .colorpickerBtnBackground(@color, @hoverColor: @color, @textColor: #fff) {
    background-color: @color;
    color: @textColor;
    border-color: @color;

    &:hover {
      background-color: @hoverColor;
      border-color: @hoverColor;
    }
    &.disabled {
      cursor: default;
    }
  }

  &:not(.hidden-response) {
    &.response-button-brown {
      html.legacy-colors & {
        .colorpickerBtnBackground(var(--color-brown-1100), var(--color-brown-1000));
      }

      html:not(.legacy-colors) & {
        .colorpickerBtnBackground(var(--color-brown-1000), var(--color-brown-1100));
      }

      &:focus {
        html.legacy-colors & {
          border-color: var(--color-brown-1100);
        }

        html:not(.legacy-colors) & {
          border-color: var(--color-brown-1000);
        }
        outline: none;
      }

      &.disabled {
        html.legacy-colors & {
          .colorpickerBtnBackground( var(--color-brown-disabled), var(--color-brown-disabled), var(--color-black));
        }
        html:not(.legacy-colors) & {
          .colorpickerBtnBackground( var(--color-brown-disabled), var(--color-brown-disabled), var(--color-black));
        }
      }
    }
    &.response-button-green {
      html.legacy-colors & {
        .colorpickerBtnBackground(var(--color-green-1100), var(--color-green-1000));
      }

      html:not(.legacy-colors) & {
        .colorpickerBtnBackground(var(--color-green-1100), var(--color-green-1000));
      }

      &:focus {
        html.legacy-colors & {
          border-color: var(--color-green-1100);
        }

        html:not(.legacy-colors) & {
          border-color: var(--color-green-1100);
        }
        outline: none;
      }

      &.disabled {
        html.legacy-colors & {
          .colorpickerBtnBackground( var(--color-green-disabled), var(--color-green-disabled), var(--color-black));
        }
        html:not(.legacy-colors) & {
          .colorpickerBtnBackground( var(--color-green-disabled), var(--color-green-disabled), var(--color-black));
        }
      }
    }
    &.response-button-orange {
      html.legacy-colors & {
        .colorpickerBtnBackground(var(--color-orange-1100), var(--color-orange-1000), var(--color-black));
      }

      html:not(.legacy-colors) & {
        .colorpickerBtnBackground(var(--color-orange-1000), var(--color-orange-1100), var(--color-black));
      }

      &:focus {
        html.legacy-colors & {
          border-color: var(--color-orange-1100);
        }

        html:not(.legacy-colors) & {
          border-color: var(--color-orange-1000);
        }
        outline: none;
      }

      &.disabled {
        html.legacy-colors & {
          .colorpickerBtnBackground( var(--color-orange-disabled), var(--color-orange-disabled), var(--color-black));
        }
        html:not(.legacy-colors) & {
          .colorpickerBtnBackground( var(--color-orange-disabled), var(--color-orange-disabled), var(--color-black));
        }
      }
    }
    &.response-button-purple {
      html.legacy-colors & {
        .colorpickerBtnBackground(var(--color-purple-1000), var(--color-purple-1100));
      }

      html:not(.legacy-colors) & {
        .colorpickerBtnBackground(var(--color-plum-1100), var(--color-plum-900));
      }

      &:focus {
        html.legacy-colors & {
          border-color: var(--color-purple-1000);
        }

        html:not(.legacy-colors) & {
          border-color: var(--color-plum-1100);
        }
        outline: none;
      }

      &.disabled {
        html.legacy-colors & {
          .colorpickerBtnBackground( var(--color-purple-disabled), var(--color-purple-disabled));
        }
        html:not(.legacy-colors) & {
          .colorpickerBtnBackground( var(--color-plum-600), var(--color-plum-600));
        }
      }
    }
    &.response-button-yellow {
      html.legacy-colors & {
        .colorpickerBtnBackground(var(--color-yellow-1100), var(--color-yellow-1000), var(--color-stonegrey-1200));
      }

      html:not(.legacy-colors) & {
        .colorpickerBtnBackground(var(--color-yellow-1000), var(--color-yellow-1100), var(--color-stonegrey-1200));
      }

      &:focus {
        html.legacy-colors & {
          border-color: var(--color-yellow-1100);
        }

        html:not(.legacy-colors) & {
          border-color: var(--color-yellow-1000);
        }
        outline: none;
      }

      &.disabled {
        html.legacy-colors & {
          .colorpickerBtnBackground( var(--color-yellow-disabled), var(--color-yellow-disabled), var(--color-stonegrey-1200));
        }
        html:not(.legacy-colors) & {
          .colorpickerBtnBackground( var(--color-yellow-disabled), var(--color-yellow-disabled), var(--color-stonegrey-1200));
        }
      }
    }
    &.response-button-blue {
      html.legacy-colors & {
        .colorpickerBtnBackground(var(--color-blue-1100), var(--color-blue-1000));
      }

      html:not(.legacy-colors) & {
        .colorpickerBtnBackground(var(--color-blueberry-1000), var(--color-blueberry-1100));
      }

      &:focus {
        html.legacy-colors & {
          border-color: var(--color-blue-1100);
        }

        html:not(.legacy-colors) & {
          border-color: var(--color-blueberry-1000);
        }
        outline: none;
      }

      &.disabled {
        html.legacy-colors & {
          .colorpickerBtnBackground( var(--color-blue-disabled), var(--color-blue-disabled), var(--color-white));
        }
        html:not(.legacy-colors) & {
          .colorpickerBtnBackground( var(--color-blueberry-300), var(--color-blueberry-300), var(--color-blueberry-1000));
        }
      }
    }
    &.response-button-red {
      html.legacy-colors & {
        .colorpickerBtnBackground(var(--color-red-1100), var(--color-red-1000));
      }

      html:not(.legacy-colors) & {
        .colorpickerBtnBackground(var(--color-grapefruit-1000), var(--color-grapefruit-1100));
      }

      &:focus {
        html.legacy-colors & {
          border-color: var(--color-red-1100);
        }

        html:not(.legacy-colors) & {
          border-color: var(--color-grapefruit-1000);
        }
        outline: none;
      }

      &.disabled {
        html.legacy-colors & {
          .colorpickerBtnBackground( var(--color-red-disabled), var(--color-red-disabled), var(--color-white));
        }
        html:not(.legacy-colors) & {
          .colorpickerBtnBackground( var(--color-grapefruit-300), var(--color-grapefruit-300), var(--color-grapefruit-1100));
        }
      }
    }
    &.response-button-teal {
      html.legacy-colors & {
        .colorpickerBtnBackground( var(--color-teal-1100), var(--color-teal-1000));
      }

      html:not(.legacy-colors) & {
        .colorpickerBtnBackground( var(--color-avocado-900), var(--color-avocado-1000));
      }

      &:focus {
        html.legacy-colors & {
          border-color: var(--color-teal-1100);
        }

        html:not(.legacy-colors) & {
          border-color: var(--color-avocado-900);
        }
        outline: none;
      }

      &.disabled {
        html.legacy-colors & {
          .colorpickerBtnBackground( var(--color-teal-disabled), var(--color-teal-disabled), var(--color-stonegrey-1200));
        }
        html:not(.legacy-colors) & {
          .colorpickerBtnBackground( var(--color-avocado-400), var(--color-avocado-400), var(--color-avocado-900));
        }
      }
    }
    &.response-button-gray {
      html.legacy-colors & {
        .colorpickerBtnBackground( var(--color-stonegrey-1000), var(--color-stonegrey-1100));
      }

      html:not(.legacy-colors) & {
        .colorpickerBtnBackground( var(--color-stonegrey-1000), var(--color-stonegrey-1100));
      }

      &:focus {
        html.legacy-colors & {
          border-color: var(--color-stonegrey-1000);
        }

        html:not(.legacy-colors) & {
          border-color: var(--color-stonegrey-1000);
        }
        outline: none;
      }

      &.disabled {
        html.legacy-colors & {
          .colorpickerBtnBackground( var(--color-stonegrey-500), var(--color-stonegrey-500), var(--color-stonegrey-1000));
        }
        html:not(.legacy-colors) & {
          .colorpickerBtnBackground( var(--color-stonegrey-500), var(--color-stonegrey-500), var(--color-stonegrey-1000));
        }
      }
    }
  }
}
