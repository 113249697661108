@thermWidth: 30px;
@thermHeight: 300px;

.level {
  position: absolute;
  white-space: nowrap;
  font-size: 12px;
  display: flex;

  justify-content: flex-end;
  align-items: center;

  .level-label {
    text-align: right;
    padding-bottom: 6px;
    line-height: 1.3;

    @media (max-width: 600px) {
      width: 172px;
      max-width: 172px;
      white-space: initial;
    }

    @media (max-width: 360px) {
      width: 159px;
      max-width: 159px;
    }
  }

  .level-degrees {
    font-weight: 500;
    width: 55px;
    margin-left: @spaceS;
    border-bottom: 1px solid var(--color-stonegrey-900);
    line-height: 1.3;
    padding-bottom: 6px;
  }
}

.therm-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 85px;
  margin-left: 40%;
  margin-right: 40%;

  @media (max-width: 600px) {
    /* Attempts to fit all the elements on narrow views (labels, thermometer and value input) */
    margin-top: 70px;
    margin-left: 10%;
    margin-right: 10%;
    justify-content: flex-end;
  }

  &.hidden-response {
    .therm-drag,
    .results .on {
      display: none;
    }

    .results .off {
      background-color: var(--color-stonegrey-400);
      bottom: -19px;
    }
  }

  .slider-indicator {
    position: relative;
  }

  .slider-indicator-line {
    background-color: var(--color-stonegrey-1100);
    width: 100%;
    height: 4px;
    border-radius: 10px;
  }

  .therm-drag {
    position: absolute;
    width: @thermWidth + 30;
    left: -13px;
    z-index: 1;
    bottom: auto !important; // ensure we've stuck to the top of the 'red'

    .slider-input {
      max-width: 50px;
      text-align: center;
      font-weight: 600;
      left: 80px;
      .vertical-align();

      @media (max-width: 600px) {
        display: none;
      }
    }
  }
}

.therm_cont {
  div.temperature-indicator-mobile {
    font-weight: 600;
    display: none;

    @media (max-width: 600px) {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 30px;
    }

    .slider-input-mobile {
      display: none;
      @media (max-width: 600px) {
        margin-left: 5px;
        display: flex;
        max-width: 50px;
        font-weight: 600;
      }
    }
  }
}

.slider-indicator-dot {
  background-color: var(--color-stonegrey-1100);
  width: 13px;
  height: 13px;
  border-radius: 50%;
  .vertical-align();
  right: 0;
}

.levels,
.thermometer {
  height: @thermHeight;
}

.levels {
  display: flex;
  position: relative;
  align-items: flex-end;
  flex-direction: column-reverse;
  margin-right: @spaceM;
}

.thermometer {
  width: @thermWidth;
  position: relative;
}

.thermometer > .results {
  width: 100%;
  height: 100%;
  cursor: crosshair;
  border: 10px solid var(--color-stonegrey-400);
  background-color: var(--color-stonegrey-400);
  z-index: 1;
  position: absolute;
  border-bottom: 0;
  border-top: 0;
  box-sizing: border-box;

  &:before {
    content: ' ';
    width: @thermWidth * 1.5;
    height: @thermWidth * 1.5;
    position: absolute;
    bottom: -74px;
    z-index: 0;
    background-color: var(--color-grapefruit-1000);
    border-radius: 50%;
    border: 10px solid var(--color-stonegrey-400);
    .horizontal-align();

    .therm-min {
      width: 20px;
      height: 20px;
      background-color: blue;
      top: -10px;
      .horizontal-align();
    }
  }
}

.top-rounded {
  height: 28px;
  width: 100%;
  top: -14px;
  background-color: var(--color-stonegrey-400);
  border-radius: 70px 70px 0 0;
  .horizontal-align();
}

.thermometer .results {
  .on,
  .off {
    width: 100%;
    height: 0;
    background-color: var(--color-grapefruit-1000);
    position: absolute;
    left: 0;
    display: inline;
    min-height: 0;
    max-height: 100%;
  }

  .on {
    border-radius: 70px 70px 0 0;
    bottom: 0;
    padding-bottom: 3px;
  }

  .off {
    height: 20px;
    bottom: -20px;
    border-left: 10px solid var(--color-stonegrey-400);
    border-right: 10px solid var(--color-stonegrey-400);
    .horizontal-align();
  }
}
