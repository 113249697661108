@import '../_ygicons-font.less';

body {
  // overwrite bootstrap style
  select {
    color: var(--color-stonegrey-1000);
    font-weight: normal;
    border: 1px solid var(--color-stonegrey-900);
    padding: @spaceS / 2 @spaceXXL @spaceS / 2 @spaceM;
    height: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('../../font/ygicons/svgs/chevron-down-stonegrey-700.svg');
    background-repeat: no-repeat;
    background-size: 16px;
    background-position-x: calc(100% ~'-' @spaceM);
    background-position-y: 50%;
    min-width: 20%;
    @media (@mediaSkinny) {
      min-width: 100%;
    }
    .border-radius(2px);

    &:focus:not(.nofocus) {
      .focus();
      border-color: var(--color-stonegrey-1200);
      background-image: url('../../font/ygicons/svgs/chevron-down-stonegrey-1200.svg');
    }
    &:hover {
      border-color: var(--color-stonegrey-1200);
      color: var(--color-stonegrey-1200);
      background-image: url('../../font/ygicons/svgs/chevron-down-stonegrey-1200.svg');
    }

    &.selected {
      color: var(--color-stonegrey-1200);
      font-weight: 600;
      background-image: url('../../font/ygicons/svgs/chevron-down-stonegrey-1200.svg');
    }
  }
}
