// Copy only needed styles directly from jQuery UI's CSS rather than include all
// of it.
.question-video-dial {
  .ui-slider {
    position: relative;
    text-align: left;
  }
  .ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 1.2em;
    height: 1.2em;
    cursor: default;
  }
  .ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: 0.7em;
    display: block;
    border: 0;
    background-position: 0 0;
  }

  .ui-slider-horizontal {
    height: 0.8em;
  }
  .ui-slider-horizontal .ui-slider-handle {
    top: -0.7em;
    margin-left: -1.25em;
  }
  .ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 100%;
  }
  .ui-slider-horizontal .ui-slider-range-min {
    left: 0;
  }
  .ui-slider-horizontal .ui-slider-range-max {
    right: 0;
  }

  .ui-slider-vertical {
    width: 0.8em;
    height: 100px;
  }
  .ui-slider-vertical .ui-slider-handle {
    left: -0.3em;
    margin-left: 0;
    margin-bottom: -1.25em;
  }
  .ui-slider-vertical .ui-slider-range {
    left: 0;
    width: 100%;
  }
  .ui-slider-vertical .ui-slider-range-min {
    bottom: 0;
  }
  .ui-slider-vertical .ui-slider-range-max {
    top: 0;
  }
}

.question-video-dial {
  #videoplayer {
    position: relative;
    min-height: 450px;
    display: inline-block;
    float: left;
    width: 100%;
    margin-bottom: 20px;

    #touchPlayBtn {
      position: absolute;
      bottom: 35%;
      left: 46%;
      z-index: 9999;
      text-align: center;
      padding: 0;
      cursor: pointer;

      i {
        color: #fff;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.65);
        font-size: 64px;
        display: block;
        margin: 0 auto;
      }
    }

    .vjs-full-window #touchPlayBtn {
      bottom: 50%;
      position: fixed;
    }
  }

  .video-js-canvas-wrapper {
    position: absolute;
    top: 0;
    left: 0;
  }
}

@import (less) '../../../vendor/video-dial/lib/video-dial.css';

#video-dial .ui-slider-handle {
  background: transparent url('../../img/videodial-sprite.png') no-repeat left
    bottom !important;
  border: none !important;
  height: 26px;
  width: 26px;
}
#video-dial .ui-slider-handle.playing {
  background-position: -52px 0 !important;
}
#video-dial .ui-slider-handle.paused {
  background-position: -26px 0 !important;
}

@media only screen and (max-device-width: 1024px) {
  .question-video-dial .ui-slider-horizontal {
    height: 1.5em;
  }
  #video-dial .ui-slider-handle {
    height: 34px;
    background-position: 0 -34px !important;
    border-radius: 0px;
    top: 15px;
  }
  #video-dial .ui-slider-handle.playing {
    background-position: -52px -34px !important;
  }
  #video-dial .ui-slider-handle.paused {
    background-position: -26px -34px !important;
  }

  .vjs-full-window-iphone #video-dial .ui-slider-handle,
  .vjs-full-window-touch #video-dial .ui-slider-handle {
    background-position: 0 0 !important;
    top: -34px;
  }
  .vjs-full-window-iphone #video-dial .ui-slider-handle.playing,
  .vjs-full-window-touch #video-dial .ui-slider-handle.playing {
    background-position: -52px 0 !important;
  }
  .vjs-full-window-iphone #video-dial .ui-slider-handle.paused,
  .vjs-full-window-touch #video-dial .ui-slider-handle.paused {
    background-position: -26px 0 !important;
  }
}
