/* Use http://designer.videojs.com/ to build custom CSS. */

// Wrapped in .question-video so that specificty wins, whatever order
// this is loaded in w.r.t. stock video.js.css
// FIXME: We really need our own, simplified skin so that no
// overrides are necessary.
.question-video {
  .vjs-default-skin {
    .vjs-big-play-button {
      /* Center it horizontally */
      left: 50%;
      margin-left: -2.1em;
      /* Center it vertically */
      top: 50%;
      margin-top: -1.4000000000000001em;

      &:focus {
        box-shadow: 0 0 0 4px var(--color-blueberry-300);
      }
    }

    &.vjs-paused .vjs-big-play-button {
      display: block;
      opacity: 1;
    }

    &.vjs-has-started:not(.vjs-paused) .vjs-big-play-button {
      display: none !important;
      opacity: 0;
    }

    .vjs-volume-control {
      margin-right: 10px;
    }

    .vjs-slider {
      &:focus {
        box-shadow: 0 0 0 4px var(--color-blueberry-300);
      }
    }
  }

  .vjs-progress-holder {
    display: none;
  }

  .vjs-fullscreen-control {
    visibility: hidden;
  }
}

.question {
  .video-js {
    font-family: @baseFontFamily;
    margin-left: auto;
    margin-right: auto;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
}

// http://stackoverflow.com/a/29780590
video::-internal-media-controls-overlay-cast-button {
  display: none;
}
