//Override the default modal from bootstrap
.modal {
  border-radius: 0;
  display: none;

  .modal-header {
    border: 0px;
    display: flex;

    .modal-close-toggle {
      flex: 1;
      text-align: right;
      margin-top: 16px;
      margin-right: 16px;
      color: var(--color-stonegrey-1200);

      .popup-close {
        font-size: 24px;
        cursor: pointer;
        background: var(--color-stonegrey-300);
        .border-radius(20em);
        .box-shadow(0px 0px 0px 2px inset var(--color-stonegrey-300));
      }
    }
  }
  .modal-body {
    width: 100%;
    padding: 0px;

    .question-text {
      padding-left: 40px;
      padding-right: 40px;
    }

    .question-response-list {
      padding-left: 40px;
      padding-right: 40px;

      .question-response-column {
        width: 100%;
      }
    }
  }
  .modal-footer {
    background: none;
    border: 0px;

    #popup-nav {
      display: flex;
      &:extend(#mainNav all);

      .nav-buttons {
        flex: 1;
      }
    }
  }
}

@media (max-width: @breakpoint-md) {
  .modal {
    position: fixed;
    top: 3%;
    right: 3%;
    left: 3%;
    width: auto;
    margin: 0;
  }
  .modal-body {
    height: 60%;
  }
}
