.openend-inline {
  .@{dynamicRowName} & {
    display: inline;
    .ie7-inline-block();
    max-width: 100%;
    // Fix alignment in all IE versions
    vertical-align: baseline;
  }

  input,
  textarea {
    padding: 0 6px;
    font-size: @baseFontSize;
    margin: 4px 8px 4px 5px;
    vertical-align: baseline;

    .@{dynamicRowName} & {
      @inputBorderSize: 2px;
      .box-sizing(border-box);
      max-width: 100%;
      margin: 0;
      height: 48px;

      @media (@mediaSkinny) {
        margin-top: @spaceS;
        width: 90%;
      }
    }
  }

  textarea {
    vertical-align: top;

    .@{dynamicRowName} & {
      height: auto;
      min-height: 48px;
    }
  }
}

.openend-inline-prompt {
  margin-right: @spaceL;
}

.openend-inline-error {
  &:extend(.text-error);
  display: inline-block;
  .ie7-inline-block();
}

textarea,
input {
  &[readonly] {
    background-color: var(--color-stonegrey-100);
    border-color: var(--color-stonegrey-500);
    color: transparent;
  }
}
