.wide_view_horizontal {
  .responsive-cell {
    &.left-message,
    &.right-message {
      width: 178px;
      display: flex;
      align-items: center;
    }

    &.left-message {
      padding-right: 16px;
      text-align: right;
      justify-content: end;

      @media all and (max-width: 768px) {
        text-align: left;
        justify-content: start;
      }
    }

    &.right-message {
      padding-left: 16px;
      text-align: left;
      justify-content: start;

      @media all and (max-width: 768px) {
        text-align: right;
        justify-content: end;
      }
    }

    &.center-widget {
      flex: 1;
    }
  }

  @media all and (max-width: 768px) {
    .responsive-grid {
      flex-wrap: wrap;
    }

    .responsive-cell {
      &.left-message,
      &.right-message {
        max-width: none;
        width: ~'calc(50% - 16px)';
      }

      &.left-message {
        order: 1;
      }

      &.right-message {
        order: 2;
      }

      &.center-widget {
        order: 3;
        width: 100%;
        margin-top: 17px;
      }
    }
  }
}

.dynamic_page_name {
  position: fixed;
  top: 5px;
  right: 10px;
  color: darkred;
  opacity: 0.5;
  font-weight: bold;
}

.narrow_view_horizontal {
  @media (max-width: 768px) {
    padding: 0 30px;

    &.wide_view_vertical {
      padding: 0;
    }
  }
}

.narrow_view_vertical.wide_view_horizontal,
.narrow_view_horizontal.wide_view_vertical {
  .responsive-grid-skinny {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .responsive-cell {
      &.left-message,
      &.right-message {
        justify-content: center;
        padding: 0;
        width: 100%;
      }
      &.right-message {
        order: 3;
      }
      &.center-widget.rule-main-cell-skinny {
        position: relative;
        order: 2;
        width: 100%;
        padding: 0;
        max-width: 24px;
        margin-top: 16px;
        margin-bottom: 16px;

        .rule-ruler {
          width: 100%;
          min-height: 300px;
          max-height: 433px;
          height: 100%;
          .rule-drag {
            margin-left: 0;
            margin-top: -@xl-gap - 2;
            height: @xl-gap * 2;
            .transform(rotate(90deg));
            .slider-indicator {
              height: @xl-gap * 2;
              top: -13px;
            }
            .slider-indicator-dot {
              margin: 0;
              bottom: -@spaceL / 2;
              top: auto;
              right: -13px;
              left: auto;
            }
            .slider-input {
              height: 15px;
              .transform(translateX(-50%) rotate(-90deg));
            }
          }
          .rule-levels {
            flex-direction: column;
            .rule-level {
              border: none;
              border-bottom: 1px solid var(--color-stonegrey-900);
              width: 50%;
              align-self: flex-end;
              &:last-child {
                border: none;
              }
            }
          }
        }
      }
    }
  }
}

.narrow_view_vertical.wide_view_horizontal {
  .responsive-grid-wide {
    display: flex;
    @media all and (max-width: 640px) {
      display: none;
    }
  }
  .responsive-grid-skinny {
    display: none;
    @media all and (max-width: 640px) {
      display: flex;
    }
  }
}

.narrow_view_horizontal.wide_view_horizontal {
  .responsive-grid-wide {
    display: flex;
  }
  .responsive-grid-skinny {
    display: none;
  }
  @media (max-width: 640px) {
    .rule-table.rule .rule-inner.slider-input-visible {
      padding-bottom: 75px;
    }
  }
}

.narrow_view_horizontal.wide_view_vertical,
.narrow_view_vertical.wide_view_vertical {
  .responsive-grid-wide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .responsive-grid-skinny {
    display: none;
  }
}

// Hide reCAPTCHA badge
.grecaptcha-badge {
  visibility: hidden;
}
