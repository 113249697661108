// This file should NOT need to be modified!
// It's a copy of v2.1.1's _base.sass file, with reasonable SASS-to-LESS
// transformations.

#uniform-mixins {
  .opacity(@opacity) {
    @new-opacity: round(@opacity * 100);
    opacity: @opacity;
    filter: ~'alpha(opacity=@{opacity})';
    -moz-opacity: @opacity;
  }

  .hideYetClickable() {
    #uniform-mixins > .opacity(0);
    border: none;
    background: none;
  }

  .inline-block() {
    display: -moz-inline-box;
    display: inline-block;
    *display: inline;
    zoom: 1;
  }

  .ellipsis() {
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
  }

  .border-radius(@radius) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    border-radius: @radius;
  }

  .box-shadow(@def) {
    -webkit-box-shadow: @def;
    -moz-box-shadow: @def;
    box-shadow: @def;
  }

  .focus() {
    -webkit-box-shadow: 0 0 0 2px var(--color-stonegrey-300) inset,
      0 0 0 2px var(--color-blueberry-300) !important;
    -moz-box-shadow: 0 0 0 2px var(--color-stonegrey-300) inset,
      0 0 0 2px var(--color-blueberry-300) !important;
    box-shadow: 0 0 0 2px var(--color-stonegrey-300) inset,
      0 0 0 2px var(--color-blueberry-300) !important;
  }

  .focus-checkbox() {
    -webkit-box-shadow: 0 0 0 2px var(--color-blueberry-300);
    -moz-box-shadow: 0 0 0 2px var(--color-blueberry-300);
    box-shadow: 0 0 0 2px var(--color-blueberry-300);
  }

  .checkbox-checked() {
    background: var(--color-black);
    border-color: var(--color-black);
    border-width: 2px;
    position: relative;
    left: -1px;
  }

  .checkbox-before() {
    position: absolute;
    top: 3px;
    left: 2px;
    font-size: 12px;
    line-height: 1;
    color: @white;
    font-family: 'Icons';
    .ygicon-checkmark;
  }

  .retina() {
  }

  .use-backgrounds(@sprite, @sprite-size, @input, @input-focus, @input-size) {
    div@{class-wrapper} {
      &@{class-select},
      &@{class-select} span,
      &@{class-checkbox} span,
      &@{class-radio} span,
      &@{class-upload},
      &@{class-upload} span@{class-action},
      &@{class-button},
      &@{class-button} span {
        background-image: @sprite;

        #uniform-mixins > .sprite-background-size(@sprite-size);
      }
    }

    @{class-wrapper-element}@{class-wrapper} input@{class-input},
    @{class-wrapper-element}@{class-wrapper} select@{class-multiselect},
    @{class-wrapper-element}@{class-wrapper} textarea@{class-textarea} {
      background-image: @input;

      #uniform-mixins > .sprite-background-size(@sprite-size, @input-size);

      &@{class-active} {
        background-image: @input-focus;
      }
    }
  }

  .sprite-background-size(@sprite-size, @input-size) when (@sprite-size > 0) {
    background-size: @input-size;
  }
  .sprite-background-size(@sprite-size) when (@sprite-size > 0) {
    background-size: @sprite-size;
  }
}

@sprite: ~'../img/sprite.png';
@sprite-retina: ~'../img/sprite-retina.png';
@sprite-size: 493px;
@button-height: 30px;
@button-margin-left: 13px;
@button-padding: 0;
@button-span-height: @button-height;
@checkbox-height: 18px;
@checkbox-width: 18px;
@input-padding: 3px;
@input-background: ~'../img/bg-input.png';
@input-background-retina: ~'../img/bg-input-retina.png';
@input-background-focus: ~'../img/bg-input-focus.png';
@input-background-focus-retina: ~'../img/bg-input-focus-retina.png';
@input-background-size: 1px;
@radio-height: 18px;
@radio-width: 18px;
@select-fixed-width: 190px;
@select-height: 26px;
@select-margin-left: 10px;
@select-margin-right: 25px;
@select-select-height: 22px;
@select-select-top: 2px;
@upload-action-width: 85px;
@upload-filename-margin-top: 2px;
@upload-filename-margin-bottom: 2px;
@upload-filename-margin-left: 2px;
@upload-filename-width: 82px;
@upload-filename-padding: 0 10px;
@upload-height: 28px;
@upload-width: 190px;

@checkbox-voffset: (-10 * @select-height);
@radio-voffset: (@checkbox-voffset - @checkbox-height);
@upload-voffset: (@radio-voffset - @radio-height);
@button-voffset: (@upload-voffset - (8 * @upload-height));

@class-action: ~'.action';
@class-active: ~'.active';
@class-button: ~'.button';
@class-checkbox: ~'.checker';
@class-checked: ~'.checked';
@class-disabled: ~'.disabled';
@class-input: ~'.uniform-input';
@class-filename: ~'.filename';
@class-focus: ~'.focus';
@class-hover: ~'.hover';
@class-multiselect: ~'.uniform-multiselect';
@class-radio: ~'.radio';
@class-select: ~'.selector';
@class-upload: ~'.uploader';
@class-textarea: ~'.uniform';
@class-wrapper: ~'';

.set-class-wrapper-element() when (@class-wrapper = ~'') {
  @class-wrapper-element: ~'';
}
.set-class-wrapper-element() when not (@class-wrapper = ~'') {
  @class-wrapper-element: ~'span';
}

.set-class-wrapper-element();

/* General settings */

div@{class-wrapper} {
  &@{class-select},
  &@{class-select} span,
  &@{class-upload},
  &@{class-upload} span@{class-action},
  &@{class-button},
  &@{class-button} span {
    background-image: url(@sprite);
    background-repeat: no-repeat;
    -webkit-font-smoothing: antialiased;
  }

  &@{class-checkbox} span,
  &@{class-radio} span {
    -webkit-font-smoothing: antialiased;
  }

  &@{class-select},
  &@{class-checkbox},
  &@{class-button},
  &@{class-radio},
  &@{class-upload} {
    #uniform-mixins > .inline-block();
    vertical-align: middle;

    /* Keeping this as :focus to remove browser styles */
    &:focus {
      outline: 0;
    }
  }

  &@{class-select},
  &@{class-radio},
  &@{class-checkbox},
  &@{class-upload},
  &@{class-button} {
    &,
    & * {
      margin: 0;
      padding: 0;
    }
  }
}

.highContrastDetect {
  background: url(@input-background) repeat-x 0 0;
  width: 0px;
  height: 0px;
}

/* Input & Textarea */

@{class-wrapper-element}@{class-wrapper} input@{class-input},
@{class-wrapper-element}@{class-wrapper} select@{class-multiselect},
@{class-wrapper-element}@{class-wrapper} textarea@{class-textarea} {
  padding: @input-padding;
  background: url(@input-background) repeat-x 0 0;
  outline: 0;

  &@{class-active} {
    background: url(@input-background-focus) repeat-x 0 0;
  }
}

/* Remove default webkit and possible mozilla .search styles.
 * Keeping this as :active to remove browser styles */
div@{class-wrapper}@{class-checkbox} input,
input[type='search'],
input[type='search']:active {
  -moz-appearance: none;
  -webkit-appearance: none;
}

/* Select */

div@{class-wrapper}@{class-select} {
  background-position: 0 (-5 * @select-height);
  line-height: @select-height;
  height: @select-height;
  padding: 0 0 0 @select-margin-left;
  position: relative;
  overflow: hidden;

  span {
    #uniform-mixins > .ellipsis();
    background-position: right 0;
    height: @select-height;
    line-height: @select-height;
    padding-right: @select-margin-right;
    cursor: pointer;
    width: 100%;
    display: block;
  }

  &.fixedWidth {
    width: @select-fixed-width;

    span {
      width: (@select-fixed-width - @select-margin-left - @select-margin-right);
    }
  }

  select {
    #uniform-mixins > .hideYetClickable();
    position: absolute;
    height: @select-select-height;
    top: @select-select-top;
    left: 0px;
    width: 100%;
  }

  &@{class-active} {
    background-position: 0 (-6 * @select-height);

    span {
      background-position: right (-1 * @select-height);
    }
  }

  &@{class-hover},
  &@{class-focus} {
    background-position: 0 (-7 * @select-height);

    span {
      background-position: right (-2 * @select-height);
    }

    &@{class-active} {
      background-position: 0 (-8 * @select-height);

      span {
        background-position: right (-3 * @select-height);
      }
    }
  }

  &@{class-disabled},
  &@{class-disabled}@{class-active} {
    background-position: 0 (-9 * @select-height);

    span {
      background-position: right (-4 * @select-height);
    }
  }
}

/* Checkbox */

div@{class-wrapper}@{class-checkbox} {
  position: relative;

  &,
  span,
  input {
    width: @checkbox-width;
    height: @checkbox-height;
  }

  span {
    #uniform-mixins > .inline-block();
    text-align: center;

    &@{class-checked}:not(.hidden-response) {
      #uniform-mixins > .checkbox-checked();

      &:before {
        #uniform-mixins > .checkbox-before();
      }
    }

    background: none;
    background-position: 0 0;
    border: 1px solid var(--color-stonegrey-900);
    .border-radius(2px);
  }

  input {
    #uniform-mixins > .hideYetClickable();
    #uniform-mixins > .inline-block();
  }

  &@{class-active} {
    span:not(.hidden-response) {
      &@{class-checked}:before {
        #uniform-mixins > .checkbox-before();
      }

      &@{class-checked} {
        #uniform-mixins > .checkbox-checked();
      }
    }
  }

  &@{class-hover},
  &@{class-focus} {
    span:not(.hidden-response) {
      &@{class-checked}:before {
        #uniform-mixins > .checkbox-before();
      }

      &@{class-checked} {
        #uniform-mixins > .checkbox-checked();
      }
    }

    &@{class-active} {
      span:not(.hidden-response) {
        &@{class-checked}:before {
          #uniform-mixins > .checkbox-before();
        }
        &@{class-checked} {
          #uniform-mixins > .checkbox-checked();
        }
      }
    }
  }

  &@{class-focus} {
    span {
      border-width: 2px;
      border-color: var(--color-black);
      position: relative;
      left: -1px;
      #uniform-mixins > .focus-checkbox();
    }
  }

  &@{class-disabled},
  &@{class-disabled}@{class-active} {
    span:not(.hidden-response) {
      &@{class-checked}:before {
        #uniform-mixins > .checkbox-before();
      }

      &@{class-checked} {
        #uniform-mixins > .checkbox-checked();
      }
    }
  }
}

/* Radio */

div@{class-wrapper}@{class-radio} {
  position: relative;

  &,
  span,
  input {
    width: @radio-width;
    height: @radio-height;
  }

  span {
    #uniform-mixins > .inline-block();
    text-align: center;

    &@{class-checked}:not(.hidden-response) {
      .checked-style();
    }

    background: none;
    background-position: 0 0;
    border: 1px solid var(--color-stonegrey-900);
    .border-radius(20em);
  }

  input {
    #uniform-mixins > .hideYetClickable();
    #uniform-mixins > .inline-block();
    text-align: center;
  }

  &@{class-active} {
    span:not(.hidden-response) {
      &@{class-checked} {
        .checked-style();
      }
    }
  }

  &@{class-hover},
  &@{class-focus} {
    &@{class-active} {
      span:not(.hidden-response) {
        &@{class-checked} {
          .checked-style();
        }
      }
    }
  }

  &@{class-focus} {
    span {
      border-width: 2px;
      border-color: var(--color-black);
      position: relative;
      top: -1px;
      left: -1px;
      #uniform-mixins > .focus();
    }
  }

  &@{class-disabled},
  &@{class-disabled}@{class-active} {
    span {
      &@{class-checked} {
        .checked-style();
      }
    }
  }
}

.checked-style() {
  .box-shadow(0px 0px 0px 2px var(--color-stonegrey-300) inset);
  background: var(--color-stonegrey-1200);
  border-color: var(--color-black);
  border-width: 2px;
  position: relative;
  top: -1px;
  left: -1px;
}

label:hover div@{class-wrapper}@{class-radio} span,
label:hover div@{class-wrapper}@{class-checkbox} span {
  border-color: var(--color-black);
  background-color: var(--color-white);
  border-width: 2px;
  position: relative;
  left: -1px;
}

label:hover div@{class-wrapper}@{class-radio} span {
  top: -1px;
}

/* Uploader */

div@{class-wrapper}@{class-upload} {
  background-position: 0 @upload-voffset;
  height: @upload-height;
  width: @upload-width;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  span@{class-action} {
    background-position: right (@upload-voffset + (-4 * @upload-height));
    height: @upload-height;
    line-height: @upload-height;
    width: @upload-action-width;
    text-align: center;
    float: left;
    display: inline;
    overflow: hidden;
    cursor: pointer;
  }

  span@{class-filename} {
    #uniform-mixins > .ellipsis();
    float: left;
    cursor: default;
    height: (
      @upload-height - @upload-filename-margin-top -
        @upload-filename-margin-bottom
    );
    margin: @upload-filename-margin-top 0 @upload-filename-margin-bottom
      @upload-filename-margin-left;
    line-height: (
      @upload-height - @upload-filename-margin-top -
        @upload-filename-margin-bottom
    );
    width: @upload-filename-width;
    padding: @upload-filename-padding;
  }

  input {
    #uniform-mixins > .hideYetClickable();
    position: absolute;
    top: 0;
    right: 0;
    float: right;
    cursor: default;
    width: 100%;
    height: 100%;
  }

  &@{class-active} {
    span@{class-action} {
      background-position: right (@upload-voffset + (-6 * @upload-height));
    }
  }

  &@{class-hover},
  &@{class-focus} {
    background-position: 0 (@upload-voffset + (-2 * @upload-height));

    span@{class-action} {
      background-position: right (@upload-voffset + (-5 * @upload-height));
    }

    &@{class-active} {
      span@{class-action} {
        background-position: right (@upload-voffset + (-7 * @upload-height));
      }
    }
  }

  &@{class-disabled},
  &@{class-disabled}@{class-active} {
    background-position: 0 (@upload-voffset + (-1 * @upload-height));

    span@{class-action} {
      background-position: right (@upload-voffset + (-3 * @upload-height));
    }
  }
}

/* Buttons */

div@{class-wrapper}@{class-button} {
  background-position: 0 (@button-voffset + (-4 * @button-height));
  height: @button-height;
  cursor: pointer;
  position: relative;

  /* Keep buttons barely visible so they can get focus */
  a,
  button,
  input {
    #uniform-mixins > .opacity(0.01);
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
  }

  span {
    #uniform-mixins > .inline-block();
    line-height: @button-span-height;
    text-align: center;
    background-position: right @button-voffset;
    height: @button-span-height;
    margin-left: @button-margin-left;
    padding: @button-padding;
  }

  &@{class-active} {
    background-position: 0 (@button-voffset + (-5 * @button-height));

    span {
      background-position: right (@button-voffset + (-1 * @button-height));
      cursor: default;
    }
  }

  &@{class-hover},
  &@{class-focus} {
    background-position: 0 (@button-voffset + (-6 * @button-height));

    span {
      background-position: right (@button-voffset + (-2 * @button-height));
    }
  }

  &@{class-disabled},
  &@{class-disabled}@{class-active} {
    background-position: 0 (@button-voffset + (-7 * @button-height));

    span {
      background-position: right (@button-voffset + (-3 * @button-height));
      cursor: default;
    }
  }
}
