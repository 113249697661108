/**** Base styles are defined in the base_styles.tmpl template for inline loading. ****/

html {
  .html-sticky-footer();
}

body {
  .body-sticky-footer();
}

/* Load indicator class */
// This class is used to determine if this file was loaded.
// DO NOT REMOVE!!
#css-loaded-indicator {
  visibility: hidden;
}
/* END */

#main_cont {
  margin-bottom: @standardMargin * 2;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (@mediaSkinny) {
    min-height: 100vh;
  }
}

#notification {
  display: none;
  h4 {
    font-size: 1em;
  }
}
.footer {
  .footer-static();
  @media (@mediaSkinny) {
    // Accounting for dynamic iOS Safari Bar
    margin-bottom: 25%;
  }
}
