.ui-draggable {
  -ms-touch-action: none;
  touch-action: none;
}

.ui-widget {
  font-family: @baseFontFamily;
}

/* Datepicker */
.ui-datepicker {
  margin-top: 14px;
  border: 1px solid var(--color-stonegrey-500);

  th,
  td {
    color: var(--color-stonegrey-1200);
    font-weight: 500;
    font-size: 12px;
    padding: 2px;

    a {
      padding: @spaceXS @spaceS @spaceXS @spaceXS;
    }
  }

  td > span {
    padding: @spaceXS @spaceS @spaceXS @spaceXS;
  }

  .ui-datepicker-header {
    padding: @spaceS 0;
    background-color: var(--color-stonegrey-400);
    border: none;
    .border-radius(2px);

    .ui-datepicker-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      color: var(--color-stonegrey-1200);
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
      top: @spaceS;
      font-size: 10px;
      font-family: 'Icons' !important;
      font-weight: 400;
      width: 24px;
      height: 24px;
      background: var(--color-stonegrey-1000);
      color: var(--color-white);
      border: none;
      .border-radius(24px);

      &-hover {
        top: @spaceS;
        cursor: pointer;
      }

      .ui-icon {
        display: none;
        background: none;
      }
    }

    .ui-datepicker-prev {
      left: @spaceS;

      &-hover {
        left: @spaceS;
      }

      &::before {
        content: '\e903';
        .horizontal-align();
      }
    }

    .ui-datepicker-next {
      right: @spaceS;

      &-hover {
        right: @spaceS;
      }

      &::before {
        content: '\e904';
        .horizontal-align();
      }
    }
  }
}

.ui-datepicker-calendar {
  .ui-state-default,
  .ui-state-highlight {
    font-weight: 500;
    color: var(--color-stonegrey-1200);
    background-color: var(--color-stonegrey-400);
    border: none;
    .border-radius(2px);
  }

  .ui-state-active {
    color: var(--color-white);
    background-color: var(--color-stonegrey-1200);
  }

  .ui-state-disabled {
    font-weight: normal !important;
  }
}
/* end Datepicker */
