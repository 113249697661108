/**
How to add new icons:
- Put your new svg icons in gryphon/webapp/ivw/font/ygicons/svgs
- Drop the whole folder in https://icomoon.io/app/#/select
- Select all of them and click "Generate font"
- Download it and replace gryphon/webapp/ivw/font/ygicons/ygicons.ttf
- Edit this file only with the new icons added
- To use it do: <span class="ygicon-myname"></span>
**/

@font-face {
  font-family: 'Icons';
  src: url('../font/ygicons/ygicons.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ygicon-,
[class^='ygicon-'],
[class*=' ygicon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ygicon-down:before {
  content: '\e902';
}
.ygicon-up:before {
  content: '\e901';
}
.ygicon-right:before {
  content: '\e904';
}
.ygicon-left:before {
  content: '\e903';
}
.ygicon-checkmark {
  content: '\e905';
}
.ygicon-popup-close:before {
  content: '\e906';
}
