// Variables file - all colors, fonts and other misc resuables go here.
:root {
  /* Stonegrey */
  --color-stonegrey-100: #f4f5f6;
  --color-stonegrey-200: #eeeff2;
  --color-stonegrey-300: #e6e8ed;
  --color-stonegrey-400: #dde1e7;
  --color-stonegrey-500: #ccd1db;
  --color-stonegrey-600: #b3bbc9;
  --color-stonegrey-700: #99a4b7;
  --color-stonegrey-800: #808da5;
  --color-stonegrey-900: #616e90;
  --color-stonegrey-1000: #4a5878;
  --color-stonegrey-1100: #33415c;
  --color-stonegrey-1200: #000000;

  /* Blueberry */
  --color-blueberry-100: #d1f0ff;
  --color-blueberry-200: #b4e7ff;
  --color-blueberry-300: #92dcff;
  --color-blueberry-400: #7fd6fe;
  --color-blueberry-500: #6acaf5;
  --color-blueberry-600: #38b8f1;
  --color-blueberry-700: #06a6ee;
  --color-blueberry-800: #0089de;
  --color-blueberry-900: #006bdb;
  --color-blueberry-1000: #005bd7;
  --color-blueberry-1100: #003cab;
  --color-blueberry-1200: #002a77;

  /* Grapefruit */
  --color-grapefruit-100: #ffefed;
  --color-grapefruit-200: #ffe5e3;
  --color-grapefruit-300: #f8e0dd;
  --color-grapefruit-600: #ff8d80;
  --color-grapefruit-700: #ff6756;
  --color-grapefruit-800: #ff412c;
  --color-grapefruit-900: #e8281e;
  --color-grapefruit-1000: #c20800;
  --color-grapefruit-1100: #980600;
  --color-grapefruit-1200: #930600;

  /* Plum */
  --color-plum-100: #fbfafc;
  --color-plum-200: #f7f6fa;
  --color-plum-300: #f2f0f6;
  --color-plum-400: #e6e3ec;
  --color-plum-500: #dad6e3;
  --color-plum-600: #c57fff;
  --color-plum-700: #b254ff;
  --color-plum-800: #9f29ff;
  --color-plum-900: #8001d8;
  --color-plum-1000: #6600ab;
  --color-plum-1100: #4f0085;
  --color-plum-1200: #241d36;

  /* Pomegranate */
  --color-pomegranate-500: #ff7bb3;
  --color-pomegranate-600: #ff4f99;
  --color-pomegranate-700: #ff2380;
  --color-pomegranate-900: #cd0e28;
  --color-pomegranate-1000: #a50115;

  /* Avocado */
  --color-avocado-400: #83dfcb;
  --color-avocado-500: #5ad5b9;
  --color-avocado-600: #31caa8;
  --color-avocado-700: #19b492;
  --color-avocado-800: #149678;
  --color-avocado-900: #00805f;
  --color-avocado-1000: #006b46;
  --color-avocado-1100: #005d3d;
  --color-avocado-1200: #00452d;

  /* Legacy Colors */
  --color-plum-disabled: #7d778c;
  --color-red-disabled: #e0a29b;
  --color-red-500: #f4e5e5;
  --color-red-700: #ff6352;
  --color-red-1000: #b21605;
  --color-red-1100: #960000;
  --color-teal-disabled: #99c3c2;
  --color-teal-1000: #006a68;
  --color-teal-1100: #004e4b;
  --color-orange-disabled: #ffe1b6;
  --color-orange-1000: #ffa726;
  --color-orange-1100: #d8850c;
  --color-yellow-disabled: #fff5cb;
  --color-yellow-1000: #ffd600;
  --color-yellow-1100: #d5b303;
  --color-yellow-1200: #503e00;
  --color-green-disabled: #abcbad;
  --color-green-1000: #2e7d32;
  --color-green-1100: #1f5f22;
  --color-blue-disabled: #a1c1e6;
  --color-blue-1000: #1565c0;
  --color-blue-1100: #0d47a1;
  --color-purple-disabled: #b5a9d9;
  --color-purple-1000: #4527a0;
  --color-purple-1100: #29126f;
  --color-brown-disabled: #b8aeab;
  --color-brown-1000: #4e342e;
  --color-brown-1100: #301e1a;
  --color-pink-1000: #ff69b4;
  --color-tan-1000: #d2b48c;
  --color-black: @black;
  --color-white: @white;
  --color-focus-outline: #ad97ed;

  /* Sizing */
  --space-xs: @spaceXS;
  --space-s: @spaceS;
  --space-m: @spaceM;
  --space-l: @spaceL;
  --space-xl: @spaceXL;
  --space-xxl: @spaceXXL;
}
@sansFontFamily: @GraphikWebfontName, 'Arial', 'Calibri', sans-serif;
@baseFontFamily: @sansFontFamily;
@baseFontSize: 16px;
@baseLineHeightFactor: 1.5;
@baseLineHeight: (@baseFontSize * @baseLineHeightFactor);

@standardMargin: @l-gap;

@bodyBackground: #fff;

// This just appears as a magic number in bootstrap's source :(
@inputPaddingAndBorder: 14px;

// Base Colors
@black: #333;
@white: #fff;

// Tables
@tableBackgroundDistinct: darken(@tableBackgroundHover, 2%);
@tableBackgroundCellHover: var(--color-stonegrey-200);

// YouGov Brand Colors
@ygRed: #ef2d27;

// Shades of grey used through the site
@ygLightGrey: #ebebeb;
@ygDarkGrey: #999999;
@ygMidGrey: #505050;

// Body Text Colors
@textPrimary: var(--color-stonegrey-1200);

@btnInverseBackground: #aaa;
@btnInverseBackgroundHighlight: #777;

// Common Media Queries
@smallDeviceWidth: 320px;
@skinnyWidth: 575px;
@slimWidth: 820px;
@mediaSkinny: ~'max-width: @{skinnyWidth}';
@mediaSlim: ~'max-width: @{slimWidth}';

@breakpoint-xs: 0;
@breakpoint-sm: 576px;
@breakpoint-md: 768px;
@breakpoint-lg: 992px;
@breakpoint-xl: 1200px;

// Standard sizes (e.g., for margins/gaps, padding, etc.)
@l-gap: 10px;
@xl-gap: 20px;

// new styling
@spaceXS: 4px;
@spaceS: 8px;
@spaceSM: 12px;
@spaceM: 16px;
@spaceL: 24px;
@spaceXL: 32px;
@spaceXXL: 40px;

// Standard animation durations
@m-duration: 0.25s;
@xl-duration: 1s;
