html[dir='rtl'] {
  direction: rtl;

  .alert {
    text-align: right;
  }

  .free-text {
    margin: 0 0 14px;
    clear: both;
    text-align: right !important;
  }

  tr.question-response-row {
    padding-right: 50px;
  }

  .icon-chevron-right {
    background-position: -432px -72px;
  }

  .ygicon-right:before {
    content: '\e903' !important;
  }

  .ygicon-left:before {
    content: '\e904' !important;
  }

  .fa-chevron-right:before {
    content: '\f053' !important;
  }

  .icon-chevron-left {
    background-position: -456px -72px;
  }

  .fa-chevron-left:before {
    content: '\f054' !important;
  }

  .grid-row .grid-item-text-left {
    border-left: 1px solid #bbb;
    margin: 4px 0;
    color: black;
    font-weight: bold;
    text-align: right;
  }

  .progress .bar {
    float: right;
  }

  .therm_middle {
    direction: ltr;
    float: right;
  }

  .grid-layout .grid-item-text-left {
    text-align: right;
  }

  .question-open-ended-main {
    text-align: right !important;
  }

  [class*='@{dynamicRowItemPrefix}'] {
    float: right !important;
  }

  .@{dynamicRowItemPrefix}-last {
    float: none !important;
  }

  select {
    background-position-x: 16px;
    padding: 4px 16px 4px 40px;
  }

  #mainNav {
    button #next_button {
      padding-left: 3px;
    }
  }

  div.uniform-radio,
  div.uniform-checker {
    margin-left: 18px;
    margin-right: 0;

    &:not(.hidden-response) span {
      right: -1px;
    }
  }

  .accordion-toggle {
    .fa-check {
      margin-left: @spaceS;
      margin-right: 0;
    }
    .toggle {
      text-align: left;
    }
  }
}
