.question-video {
  text-align: center;
}

.question-video-dial:extend(.question-video) {
}

#videoplayer {
  display: inline-block;
  width: 100%;
}
#videoplayer .video-js {
  max-width: 520px;
  width: 100% !important;
}
.video-js.vjs-fullscreen {
  max-width: 100% !important;
}
.vjs-control {
  &:focus {
    box-shadow: 0 0 0 4px var(--color-blueberry-300);
  }
}
.question-video {
  #videoplayer {
    position: relative;
    min-height: 450px;
    display: inline-block;
    float: left;
    width: 100%;

    #touchPlayBtn {
      position: absolute;
      bottom: 35%;
      left: 46%;
      z-index: 9999;
      text-align: center;
      padding: 0;
      cursor: pointer;

      i {
        color: #fff;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.65);
        font-size: 64px;
        display: block;
        margin: 0 auto;
      }
    }
  }
}
