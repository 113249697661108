@GraphikWebfontPath: '../font';
@GraphikWebfontName: 'Graphik';

/* Graphik */
@font-face {
  font-family: @GraphikWebfontName;
  src: url('@{GraphikWebfontPath}/Graphik-Light.woff2') format('woff2'),
    url('@{GraphikWebfontPath}/Graphik-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: @GraphikWebfontName;
  src: url('@{GraphikWebfontPath}/Graphik-LightItalic.woff2') format('woff2'),
    url('@{GraphikWebfontPath}/Graphik-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

// the font-weight: normal; needs to be 400
@font-face {
  font-family: @GraphikWebfontName;
  src: url('@{GraphikWebfontPath}/Graphik-Regular.woff2') format('woff2'),
    url('@{GraphikWebfontPath}/Graphik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: @GraphikWebfontName;
  src: url('@{GraphikWebfontPath}/Graphik-RegularItalic.woff2') format('woff2'),
    url('@{GraphikWebfontPath}/Graphik-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: @GraphikWebfontName;
  src: url('@{GraphikWebfontPath}/Graphik-Medium.woff2') format('woff2'),
    url('@{GraphikWebfontPath}/Graphik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: @GraphikWebfontName;
  src: url('@{GraphikWebfontPath}/Graphik-MediumItalic.woff2') format('woff2'),
    url('@{GraphikWebfontPath}/Graphik-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: @GraphikWebfontName;
  src: url('@{GraphikWebfontPath}/Graphik-Semibold.woff2') format('woff2'),
    url('@{GraphikWebfontPath}/Graphik-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: @GraphikWebfontName;
  src: url('@{GraphikWebfontPath}/Graphik-SemiboldItalic.woff2') format('woff2'),
    url('@{GraphikWebfontPath}/Graphik-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: @GraphikWebfontName;
  src: url('@{GraphikWebfontPath}/Graphik-Bold.woff2') format('woff2'),
    url('@{GraphikWebfontPath}/Graphik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: @GraphikWebfontName;
  src: url('@{GraphikWebfontPath}/Graphik-BoldItalic.woff2') format('woff2'),
    url('@{GraphikWebfontPath}/Graphik-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

.font-feature-settings(@kern: 0; @liga: 0; @lnum: 0) {
  -webkit-font-feature-settings: 'kern' @kern, 'liga' @liga, 'lnum' @lnum;
  -moz-font-feature-settings: 'kern' @kern, 'liga' @liga, 'lnum' @lnum;
  -ms-font-feature-settings: 'kern' @kern, 'liga' @liga, 'lnum' @lnum;
  -o-font-feature-settings: 'kern' @kern, 'liga' @liga, 'lnum' @lnum;
  font-feature-settings: 'kern' @kern, 'liga' @liga, 'lnum' @lnum;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* end Graphik-*/
