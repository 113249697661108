@subunit: 5px;
@mainColour: hsl(0, 0%, 94%);
@darkColour: hsl(
  hue(@mainColour),
  saturation(@mainColour),
  lightness(@mainColour) * 0.97
);
@darkerColour: hsl(
  hue(@mainColour),
  saturation(@mainColour),
  lightness(@mainColour) * 0.8
);

.transition-size () {
  -webkit-transition-property: width, height;
  -moz-transition-property: width, height;
  -o-transition-property: width, height;
  transition-property: width, height;

  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

.box-shadow () {
  @style: 0 0 3 * @subunit -@subunit black;
  -webkit-box-shadow: @style;
  -moz-box-shadow: @style;
  -o-box-shadow: @style;
  box-shadow: @style;
}

.ui-draggable.cardsort-item,
.ui-droppable label {
  font-weight: 700;
  font-size: 14px;
}

.cardsort-container {
  margin-top: @spaceL;
  overflow: hidden;
  @media (@mediaSkinny) {
    display: none;
  }
}

.cardsort-container-skinny {
  display: none;
  @media (@mediaSkinny) {
    display: block;
  }
  .cardsort-items-skinny {
    .cardsort-item-skinny {
      margin: 0 15px 15px;
      & > div {
        &:first-child {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 10px;
          &.cardsort-image-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            & > img {
              width: 106px;
              margin: 10px 0 5px;
            }
          }
        }
      }
    }
  }
}

.cardsort-bins,
.cardsort-items {
  overflow: hidden;
}

@media (max-width: @breakpoint-sm) {
  .cardsort-items.ui-droppable .cardsort-item,
  .cardsort-bin {
    width: 100% !important; // requires important to fix the jquery.cardsort.js default size
  }
}

@media (max-width: @breakpoint-sm) {
  .cardsort-item,
  .cardsort-bin {
    width: 100% !important; // requires important to fix the jquery.cardsort.js default size
  }
}

.cardsort-item {
  float: left;
  margin: 0 @spaceL @spaceL 0;
  text-align: center;
  border: 1px solid var(--color-stonegrey-900);
  overflow: hidden;
  cursor: move;
  .box-sizing(border-box);
  .transition-size;
  .border-radius(2px);
  .no-select();

  &:hover:not(.cardsort-item-empty),
  &.focus {
    background-color: var(--color-stonegrey-300);
    border-color: var(--color-stonegrey-1200);
    .box-shadow(0 2px 8px 0 rgba(67, 60, 84, 0.25));
  }

  &.cardsort-item-empty:not(.hidden-response) {
    color: #fff;
    border-color: var(--color-stonegrey-300);
    cursor: not-allowed;

    * {
      display: none;
    }
  }

  img {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 150px;
  }

  &.ui-draggable-dragging {
    background-color: #fff;
    .box-shadow();
  }

  .icon {
    position: absolute;
    top: 1px;
    left: 1px;

    button {
      opacity: 0;
    }

    .accessible-dropdown {
      position: fixed;
      margin-left: 0;
    }
  }
}

.cardsort-bin {
  float: left;
  border: 1px dashed var(--color-stonegrey-900);
  margin: 0 @spaceL @spaceL 0;
  .border-radius(2px);
  .box-sizing(border-box);
  .no-select();

  label {
    display: block;
    padding-bottom: @subunit;
    text-align: center;

    img {
      width: 100%;
    }
  }
}

.cardsort-bin-list {
  overflow: auto;

  @media (max-width: @breakpoint-sm) {
    min-height: 200px;
    height: auto !important;
  }

  &.one-column .cardsort-item {
    padding: @spaceS;
    background-color: #fff;
  }

  .cardsort-item {
    margin: 0 @spaceS @spaceS @spaceS;
    padding: @spaceS;
    background-color: #fff;
    width: ~'calc(100% - @{spaceM}) !important';

    &.hidden-response {
      visibility: hidden;
    }
  }

  // TODO: leave it until we have a confirmed grid design
  // &.one-column .cardsort-item {
  // 	width: 95%;
  // 	padding: @spaceS;
  // 	background-color: #fff;
  // }

  // &.two-column .cardsort-item {
  // 	width: 45%;
  // 	height: 60px;
  // }

  // &.three-column .cardsort-item {
  // 	width: 30%;
  // 	height: 40px;

  // 	img {
  // 		display: none;
  // 	}
  // }

  &.auto-height {
    overflow: hidden;
  }
}
.cardsort-bin-list-with-item {
  background-color: var(--color-stonegrey-300);
  border: 1px dashed var(--color-stonegrey-900);
}

.cardsort-accepts-hover {
  background-color: var(--color-stonegrey-500);
}
