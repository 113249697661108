.sprite-colorpicker-right {
  background-image: url('../../img/widgets/colorpicker/colorpicker-right-sprite.png');
  background-repeat: no-repeat;
  background-position: right;
}

.sprite-colorpicker-left {
  background-image: url('../../img/widgets/colorpicker/colorpicker-left-sprite.png');
  background-repeat: no-repeat;
  background-position: right;
}

/* Colorpicker sprites */
.sprite-colorpicker-right-blue {
  background-position: right 0;
}
.sprite-colorpicker-right-brown {
  background-position: right -116px;
}
.sprite-colorpicker-right-gray {
  background-position: right -232px;
}
.sprite-colorpicker-right-green {
  background-position: right -348px;
}
.sprite-colorpicker-right-orange {
  background-position: right -464px;
}
.sprite-colorpicker-right-purple {
  background-position: right -580px;
}
.sprite-colorpicker-right-red {
  background-position: right -696px;
}
.sprite-colorpicker-right-teal {
  background-position: right -812px;
}
.sprite-colorpicker-right-yellow {
  background-position: right -928px;
}
.sprite-colorpicker-edge-blue {
  background-position: right 0;
}
.sprite-colorpicker-edge-brown {
  background-position: right -116px;
}
.sprite-colorpicker-edge-gray {
  background-position: right -232px;
}
.sprite-colorpicker-edge-green {
  background-position: right -348px;
}
.sprite-colorpicker-edge-orange {
  background-position: right -464px;
}
.sprite-colorpicker-edge-purple {
  background-position: right -580px;
}
.sprite-colorpicker-edge-red {
  background-position: right -696px;
}
.sprite-colorpicker-edge-teal {
  background-position: right -812px;
}
.sprite-colorpicker-edge-yellow {
  background-position: right -928px;
}

/* colorpicker section */

p {
  &.colorpicker-instructions {
    font-size: 100%;
    color: black;
    padding: 10px;
    margin-top: -5px;
    text-align: center;
  }
  &.colorpicker-stem {
    width: 850px;
    font-size: 110%;
    font-weight: bold;
    color: #333333;
    margin: 0px;
    padding: 10px 10px 10px 10px;
    border: none;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
}

.stem-red {
  border-color: #fa3020;
}

.stem-green {
  border-color: #cccccc;
}

table {
  &.colorpicker-table {
    width: auto;
    margin: 0px auto;
    border-collapse: collapse;
  }
  &.colorpicker-choice-table {
    width: auto;
    border-collapse: collapse;
  }
}

tr.cp-column {
  vertical-align: top;
}

.cp-label,
.cp-choice-label,
.colorpicker-choice {
  cursor: pointer;
  width: 70px;
  height: 66px;
  text-align: center;
  font-weight: bold;
  font-size: 96%;
  line-height: 1.2em;
  color: black;
  margin: 0;
}

/* single/multiple choice colorpicker areas */

.cp-choice {
  text-align: center;
}

td.cp-choice-label,
.dyngrid-label {
  padding-right: 20px;
  font-size: 0.8em;
  line-height: 1.2em;
}

td.cp-label {
  min-width: 90px;
}
.cp-edge {
  cursor: pointer;
  height: 66px;
  width: 19px;
  img {
    max-width: 19px;
    height: 66px;
    width: 19px;
  }
}

// This block is essentially a hack. Untangling the mess
// that is colorpicker is not worth the effort.
.cp-label-text {
  display: block;
  height: auto;
}

/* cp right side of css sliding door */

.colorpicker-gray,
.colorpicker-choice.colorpicker-gray {
  .sprite-colorpicker-right;
  .sprite-colorpicker-right-gray;
}

.colorpicker-green,
.colorpicker-choice.colorpicker-green {
  .sprite-colorpicker-right;
  .sprite-colorpicker-right-green;
}

.colorpicker-blue,
.colorpicker-choice.colorpicker-blue {
  .sprite-colorpicker-right;
  .sprite-colorpicker-right-blue;
}

.colorpicker-brown,
.colorpicker-choice.colorpicker-brown {
  .sprite-colorpicker-right;
  .sprite-colorpicker-right-brown;
}

.colorpicker-orange,
.colorpicker-choice.colorpicker-orange {
  .sprite-colorpicker-right;
  .sprite-colorpicker-right-orange;
}

.colorpicker-purple,
.colorpicker-choice.colorpicker-purple {
  .sprite-colorpicker-right;
  .sprite-colorpicker-right-purple;
}

.colorpicker-red,
.colorpicker-choice.colorpicker-red {
  .sprite-colorpicker-right;
  .sprite-colorpicker-right-red;
}

.colorpicker-teal,
.colorpicker-choice.colorpicker-teal {
  .sprite-colorpicker-right;
  .sprite-colorpicker-right-teal;
}

.colorpicker-yellow,
.colorpicker-choice.colorpicker-yellow {
  .sprite-colorpicker-right;
  .sprite-colorpicker-right-yellow;
}

/* cp left side of css sliding door */

.colorpicker-edge-gray {
  .sprite-colorpicker-left;
  .sprite-colorpicker-edge-gray;
}

.colorpicker-edge-green {
  .sprite-colorpicker-left;
  .sprite-colorpicker-edge-green;
}

.colorpicker-edge-blue {
  .sprite-colorpicker-left;
  .sprite-colorpicker-edge-blue;
}

.colorpicker-edge-brown {
  .sprite-colorpicker-left;
  .sprite-colorpicker-edge-brown;
}

.colorpicker-edge-orange {
  .sprite-colorpicker-left;
  .sprite-colorpicker-edge-orange;
}

.colorpicker-edge-purple {
  .sprite-colorpicker-left;
  .sprite-colorpicker-edge-purple;
}

.colorpicker-edge-red {
  .sprite-colorpicker-left;
  .sprite-colorpicker-edge-red;
}

.colorpicker-edge-teal {
  .sprite-colorpicker-left;
  .sprite-colorpicker-edge-teal;
}

.colorpicker-edge-yellow {
  .sprite-colorpicker-left;
  .sprite-colorpicker-edge-yellow;
}

/* height and padding get adjusted in javascript */

div.colorpicker-choice {
  height: 66px;
  margin-left: 10px;
  margin-right: 0px;
  text-indent: 0px;
}
