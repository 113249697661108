.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25%;
}

.error-text-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  color: var(--color-stonegrey-1200);
  font-size: 16px;

  .body {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 16px;
    flex-direction: column;
    align-items: center;
  }

  .heading {
    font-size: 24px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 300px), (min-width: 980px) {
  .error-text-column {
    .body {
      .body-text-line-first {
        margin-right: 3px;

        &::after {
          content: ' account for new surveys every day!';
        }
      }

      .body-text-line-secound {
        display: none;
      }
    }
  }
}

@media only screen and (max-height: 600px) {
  .error-container {
    margin-top: 0%;
  }

  .error-text-column {
    margin-top: 10px;
  }

  .error-text-inner-column {
    .body {
      margin-top: 8px;
    }
  }
}

@media only screen and (min-width: 980px) {
  .error-container {
    align-items: normal;
    justify-content: normal;
    flex-direction: row-reverse;
    margin-top: 0%;
  }

  .error-text-column {
    align-items: normal;
    justify-content: normal;
    flex: 2;
    flex: auto;

    &.error-image {
      align-items: end;
    }

    .body {
      align-items: normal;
    }
  }
}
