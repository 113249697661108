.question-upload {
  .alert {
    &.alert-error {
      &.hide {
        display: none;
      }
    }
  }
}

.upload-container {
  border: 1px dashed var(--color-stonegrey-900);
  border-radius: 2px;
  padding: 111px 0px 70px;
  text-align: center;

  label {
    width: 25%;
    display: block;
    color: #999;
    font-size: 1em;
    margin: 0.3em auto 0 auto;
    pointer-events: none;
  }

  input[type='file'] {
    visibility: hidden;
    height: 1px;
  }

  .response-button {
    background-color: var(--color-blueberry-1000);
    color: var(--color-white);

    .response-button-icon {
      width: 18px;
      height: 18px;
      margin-left: 12px;
    }
  }

  div[role='button'] {
    display: inline-block;
    -webkit-box-shadow: 0 0 0 1px #fff inset;
    -moz-box-shadow: 0 0 0 1px #fff inset;
    box-shadow: 0 0 0 1px #fff inset;
    background-color: #4d90fe;
    background-image: -webkit-linear-gradient(to bottom, #4d90fe, #4787ed);
    background-image: -moz-linear-gradient(to bottom, #4d90fe, #4787ed);
    background-image: -ms-linear-gradient(to bottom, #4d90fe, #4787ed);
    background-image: -o-linear-gradient(to bottom, #4d90fe, #4787ed);
    background-image: linear-gradient(to bottom, #4d90fe, #4787ed);
    border: 1px solid #3079ed;
    color: #fff;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    cursor: default;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1.4em;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    margin: 0;
    height: auto;
    line-height: 1;
    min-width: 54px;
    outline: 0;
    padding: 0.4em 0.8em;
    z-index: 9999;

    &:hover {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      background-color: #357ae8;
      background-image: -webkit-linear-gradient(to bottom, #4d90fe, #357ae8);
      background-image: -moz-linear-gradient(to bottom, #4d90fe, #357ae8);
      background-image: -ms-linear-gradient(to bottom, #4d90fe, #357ae8);
      background-image: -o-linear-gradient(to bottom, #4d90fe, #357ae8);
      background-image: linear-gradient(to bottom, #4d90fe, #357ae8);
      border: 1px solid #2f5bb7;
      border-bottom-color: #2f5bb7;
    }
  }

  .file-list {
    display: none;
    border-top: 1px solid #eee;
    margin-top: 0;
    padding-top: 1em;
    color: #333;

    > div {
      margin-bottom: 0.35em;
    }

    span {
      opacity: 0.7;
    }

    em {
      color: #ccc;
      font-size: 0.8em;
    }

    .icon-remove {
      cursor: pointer;
    }

    button.btn-remove {
      padding: 0.25em 0.35em;
      line-height: 1;
      border-radius: 3px;
      margin-top: -10px;

      &:hover {
        background-color: #ddd;
      }
    }
  }

  .input-container {
    padding: 1.6em 1.4em 1.4em 1.4em;

    > div:first-child {
      display: inline-block;
    }
  }
}
