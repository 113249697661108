@import (reference) 'base.less';

@ygLogoW: 106px;
@ygLogoH: 23px;

/* Base YG */
body {
  font-family: @baseFontFamily;
  font-size: @baseFontSize;
  line-height: @baseLineHeight;
  color: @textPrimary;
  background-color: @bodyBackground;
  margin: 0;
  .kerning-lining-numerals-no-ligatures();
}

*:focus {
  outline: 0;
}

.focus {
  &:not(.placement-pointer-text-container) {
    box-shadow: 0 0 0 4px var(--color-blueberry-300);
    outline: none;
  }
}

.nofocus {
  box-shadow: none !important;
  outline: none !important;
}

.hover {
  background-color: var(--color-stonegrey-300);
  border-color: var(--color-stonegrey-1000);
}

.content-wrapper {
  opacity: 1 !important;
  transition: opacity linear @xl-duration;
  margin: 0 auto;
  max-width: 1020px;
  min-width: 300px;
  background: @white;
  .clearfix();
}

.content-container {
  margin: 0 40px;
}

header {
  margin: 20px 0 40px 0;
}

header .progress,
header .bar {
  height: 6px;
}

.yg-logo {
  width: @ygLogoW;
  height: @ygLogoH;
  margin: 0 0 @l-gap 0;
}

#prog_bar,
#prog_bar .bar {
  height: 6px;
}
#prog_bar {
  display: none;
  background: var(--color-stonegrey-300);
  .box-shadow(none);
  .border-radius(1px);

  .bar {
    background: var(--color-grapefruit-800);
    .box-shadow(none);
  }
}

#main_form {
  outline: none;
  margin-bottom: 96px;
  @media (@mediaSkinny) {
    margin-bottom: 48px;
  }
}

#loadingText {
  width: 190px;
  height: 50px;
  margin: 0 auto;
  font-size: 3em;
  color: #eee;
}

#loadingText .bull {
  margin: 0 10px 0 10px;
}

#notification {
  display: none;
}

#notification h4 {
  font-size: 1em;
}

footer,
#mainNav,
.spd-content {
  display: none;
}

.spd-consent-text {
  font-size: 0.9em;
  color: #777;
  line-height: 1.2;
  border-top: 2px solid #ccc;
  padding-top: 1.1em;
  padding-left: 0.35em;
  padding-right: 0.35em;
}
a.spd-consent-skip {
  display: block;
  text-align: center;
  text-decoration: underline;
  color: #777;
  font-size: 16px;
  margin: 0 auto 1.3em auto;
}

@media (@mediaSlim) {
  .content-container {
    margin: 0 @spaceM;
  }
  header {
    margin: @l-gap 0 @xl-gap 0;
  }
}

/* Alerts */

.alert {
  display: block;
  padding: 14px 35px 14px 14px;
  margin-bottom: @spaceL;
  text-shadow: none;
  border: none;
  .border-radius(2px);
  color: #856405;

  h4 {
    color: #856405;
    margin: 0;
  }

  p {
    display: block;
    margin: 0 0 12.32px;
  }

  i {
    &.fa {
      margin: 0 16px 0 8px;
      font-size: 20px;
    }
  }

  &.alert-with-icon {
    display: flex;
    align-items: center;
  }

  &.alert-danger,
  &.alert-error {
    background-color: var(--color-grapefruit-300);
    color: var(--color-grapefruit-1200);

    h4 {
      color: var(--color-grapefruit-1200);
    }
  }
  &.alert-success,
  &.alert-success h4 {
    background-color: var(--color-avocado-400);
    color: var(--color-avocado-1200);

    h4 {
      color: var(--color-avocado-1200);
    }
  }
  &.alert-warning,
  &.alert-warning h4 {
    background-color: var(--color-yellow-disabled);
    color: var(--color-yellow-1200);

    h4 {
      color: var(--color-yellow-1200);
    }
  }

  &.alert-info,
  &.alert-info h4 {
    background-color: var(--color-blueberry-100);
    color: var(--color-blueberry-1100);

    h4 {
      color: var(--color-blueberry-1100);
    }
  }

  //Old alerts
  &.alert-primary,
  &.alert-primary h4 {
    color: #004085;
    background-color: #cce5ff;
  }
  &.alert-secondary,
  &.alert-secondary h4 {
    color: #383d41;
    background-color: #e2e3e5;
  }
  &.alert-light,
  &.alert-light h4 {
    color: #818182;
    background-color: #fefefe;
  }
  &.alert-dark,
  &.alert-dark h4 {
    color: #1b1e21;
    background-color: #d6d8d9;
  }
}

textarea.alert-error,
input[type].alert.alert-error {
  border: 1px solid var(--color-grapefruit-1100);
}

.control-hint {
  color: var(--color-grapefruit-1100);
  background-color: var(--color-grapefruit-600);
  border: 0px;
  padding: 5px 10px 10px 10px;
}

/* Browser Check */

body > .buorg {
  background-color: #fff;
  border-color: #ccc;
  font-family: @baseFontFamily;
  font-size: 16px;
}
body > .buorg div {
  padding-left: 0;
  padding-right: 0;
}
body > .buorg div a {
  border-radius: 2px;
}
body > .buorg div .buorg-buttons a#buorgul {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #969696;
  background-image: -moz-linear-gradient(to bottom, #aaaaaa, #777777);
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#aaaaaa),
    to(#777777)
  );
  background-image: -webkit-linear-gradient(to bottom, #aaaaaa, #777777);
  background-image: -o-linear-gradient(to bottom, #aaaaaa, #777777);
  background-image: linear-gradient(to bottom, #aaaaaa, #777777);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffaaaaaa', endColorstr='#ff777777', GradientType=0);
  border: 1px solid #cccccc;
  border-color: #777777 #777777 #515151;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
}
body > .buorg div .buorg-buttons a#buorgig {
  background-color: #eee;
  color: #777;
}
