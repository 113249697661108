#video-dial {
  color: #ffffff;
  position: absolute;
  top: 320px;
  left: 0;
  right: 0;
  z-index: 150;

  /* Disable 'blue' selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#video-dial .ui-slider-handle {
  -ms-touch-action: none;
  touch-action: none;
}

#video-dial a,
#video-dial span {
  color: inherit;
  text-decoration: none;
}

#video-dial div.left-line,
#video-dial div.center-line,
#video-dial div.right-line {
  position: absolute;
  bottom: 1px;
  left: 50%;
  height: 24px;
  width: 1px;
  background: #ccc;
}

#video-dial div.left-line {
  left: 0;
}

#video-dial div.right-line {
  left: auto;
  right: 0;
}

#video-dial a.tooltips {
  position: relative;
  display: inline-block;
  outline: none;
  border-radius: 20em;
}

#video-dial a.tooltips i.fa {
  position: relative;
}
#video-dial a.tooltips i.fa-pause {
  left: 30%;
}
#video-dial a.tooltips i.fa-play {
  left: 38%;
}

#video-dial a.tooltips span {
  position: absolute;
  min-width: 50px;
  width: 0 auto;
  color: #ffffff;
  background: #000000;
  height: 25px;
  line-height: 25px;
  text-align: center;
  visibility: hidden;
  border-radius: 6px;
  white-space: nowrap;
  padding-left: 6px;
  padding-right: 6px;
}

#video-dial a.tooltips span:after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-bottom: 8px solid #344960;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}

#video-dial a.tooltips span {
  visibility: visible;
  background-color: #344960;
  top: 25px;
  z-index: 999;
  font-size: 1.7em;
  padding: 7px;
}

#video-dial .minmax {
  margin-top: 5px;
  font-size: 2em;
  color: silver;
  width: 100%;
}

#video-dial .min {
  float: left;
  padding-top: -15px;
}

#video-dial .min .content {
  /*margin-left:15px;*/
}

#video-dial .max .content {
  /*margin-right:15px;*/
}

#video-dial .max {
  float: right;
  text-align: right;
  padding-top: -15px;
}

#video-dial .marker {
  clear: both;
  margin: 0 auto;
}

#video-dial button {
  margin-top: 20px;
  line-height: 18px;
}
@media only screen and (min-device-width: 1024px) {
  #video-dial button {
    max-width: 520px;
  }
}

#video-dial .ui-slider-handle {
  background: #344960 !important;
  border: 1px solid #344960 !important;
  cursor: pointer;
  height: 2em;
  /*position: absolute;*/
  width: 2em;
  z-index: 2;
}

/* These values determine the radios of the handle */
#video-dial .ui-corner-all,
#video-dial .ui-corner-top,
#video-dial .ui-corner-left,
#video-dial .ui-corner-tl {
  -moz-border-radius-topleft: 0px /*{cornerRadius}*/;
  -webkit-border-top-left-radius: 0px /*{cornerRadius}*/;
  -khtml-border-top-left-radius: 0px /*{cornerRadius}*/;
  border-top-left-radius: 0px /*{cornerRadius}*/;
}
#video-dial .ui-corner-all,
#video-dial .ui-corner-top,
#video-dial .ui-corner-right,
#video-dial .ui-corner-tr {
  -moz-border-radius-topright: 0px /*{cornerRadius}*/;
  -webkit-border-top-right-radius: 0px /*{cornerRadius}*/;
  -khtml-border-top-right-radius: 0px /*{cornerRadius}*/;
  border-top-right-radius: 0px /*{cornerRadius}*/;
}
#video-dial .ui-corner-all,
#video-dial .ui-corner-bottom,
#video-dial .ui-corner-left,
#video-dial .ui-corner-bl {
  -moz-border-radius-bottomleft: 0px /*{cornerRadius}*/;
  -webkit-border-bottom-left-radius: 0px /*{cornerRadius}*/;
  -khtml-border-bottom-left-radius: 0px /*{cornerRadius}*/;
  border-bottom-left-radius: 0px /*{cornerRadius}*/;
}
#video-dial .ui-corner-all,
#video-dial .ui-corner-bottom,
#video-dial .ui-corner-right,
#video-dial .ui-corner-br {
  -moz-border-radius-bottomright: 0px /*{cornerRadius}*/;
  -webkit-border-bottom-right-radius: 0px /*{cornerRadius}*/;
  -khtml-border-bottom-right-radius: 0px /*{cornerRadius}*/;
  border-bottom-right-radius: 0px /*{cornerRadius}*/;
}

/* These values determine the look of the marker button */
/* Duplicate styling from .response-button since this is outside .less compilation */
#video-dial .btn-dial-marker {
  color: var(--color-stonegrey-1200);
  background: var(--color-stonegrey-300);
  border-color: var(--color-stonegrey-500);
  border-radius: 2px;
  padding: 14px 32px;
  cursor: pointer;
  font-family: YGMonoline, 'Raleway Webfont', Arial, Calibri, sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: var(--color-stonegrey-1200);
}
#video-dial .btn-dial-marker:hover {
  background: var(--color-stonegrey-500) !important;
  border-color: var(--color-stonegrey-700) !important;
}
#video-dial .btn-dial-marker:focus {
  border-color: var(--color-stonegrey-1200) !important;
  outline: none !important;
}
#video-dial .btn-dial-marker:active,
#video-dial .btn-dial-marker.active {
  background: var(--color-avocado-900) !important;
  border-color: var(--color-avocado-900) !important;
}

.video-js .btn-dial-fullwindow {
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 9999;
  background: transparent;
  border: none;
  box-shadow: none;
  color: #fff;
  padding: 0;
  outline: none;
}
.video-js .btn-dial-fullwindow i {
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.65);
  font-size: 32px;
}
.question-video-dial #videoplayer .vjs-full-window .btn-dial-fullwindow {
  position: fixed;
  bottom: 60px;
  right: 20px;
}
#video-dial .btn-dial-fullwindow {
  margin-left: 10px;
}
#video-dial .btn-dial-fullwindow {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #bdaf1b;
  *background-color: #bdaf1b;
  background-image: -moz-linear-gradient(to bottom, #c4e84c, #bdaf1b);
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#c4e84c),
    to(#bdaf1b)
  );
  background-image: -webkit-linear-gradient(to bottom, #c4e84c, #bdaf1b);
  background-image: -o-linear-gradient(to bottom, #c4e84c, #bdaf1b);
  background-image: linear-gradient(to bottom, #c4e84c, #bdaf1b);
  background-repeat: repeat-x;
  border-color: #bdaf1b;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#C4E84C', endColorstr='#BDAF1B', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
#video-dial .btn-dial-fullwindow:hover,
#video-dial .btn-dial-fullwindow:focus,
#video-dial .btn-dial-fullwindow:active,
#video-dial .btn-dial-fullwindow.active,
#video-dial .btn-dial-fullwindow.disabled,
#video-dial .btn-dial-fullwindow[disabled] {
  color: #ffffff;
  background-color: #bdaf1b;
  *background-color: #003bb3;
}

/* This is the class definition used to position the dial when we enter fullscreen*/
.vjs-full-window #video-dial.fixed {
  top: inherit !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  z-index: 1000 !important;
  position: fixed !important;
  background-color: black !important;
  margin: 0px auto 0px auto !important;
  padding-bottom: 10px;
}
.vjs-full-window #video-dial.fixed .video-dial-slider,
.vjs-full-window #video-dial.fixed .minmax {
  width: 98%;
  margin-left: auto;
  margin-right: auto;
}

#video-dial .minimize-string {
  display: none;
}

.vjs-full-window #video-dial .minimize-string {
  color: white;
  font-size: 2em;
  cursor: pointer;
  text-align: right;
  padding-right: 10px;
  padding-bottom: 10px;
  background-color: black;
  display: block !important;
  opacity: 1;
  filter: alpha(opacity=100);
  line-height: 25px !important;
  color: silver;
}
.video-js-canvas-wrapper {
  position: relative;
  z-index: 100;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.video-js-canvas.vjs-full-window {
  position: fixed;
  top: 30%;
  left: 10px;
  z-index: 2;
}
.vjs-full-window-iphone #video-dial,
.vjs-full-window-touch #video-dial {
  position: fixed;
  bottom: 0px;
  left: 0px;
  top: 0px;
}
.vjs-full-window-iphone #video-dial .min,
.vjs-full-window-iphone #video-dial .max,
.vjs-full-window-touch #video-dial .min,
.vjs-full-window-touch #video-dial .max {
  text-shadow: 0px 1px 1px #000;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.25);
  margin: 0 6px;
  padding: 2px 4px;
}
.vjs-full-window-iphone #video-dial .marker,
.vjs-full-window-touch #video-dial .marker {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
}
.vjs-full-window-iphone #video-dial .marker button,
.vjs-full-window-touch #video-dial .marker button {
  margin-top: 10px;
}

.video-js-canvas {
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

.video-js-canvas-overlay {
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: none;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
  .video-js {
    width: 100% !important;
  }

  .ui-slider-horizontal {
    height: 2em;
  }

  .ui-slider-horizontal .ui-slider-handle {
    top: 0.1em;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  #video-dial button {
    width: auto;
  }
}
@media only screen and (max-device-width: 768px) and (orientation: portrait) {
  .video-js-canvas.vjs-full-window {
    top: 25%;
    left: 0px;
    width: 100% !important;
    height: auto !important;
  }
}
@media only screen and (max-device-width: 667px) and (orientation: landscape) {
  .video-js-canvas.vjs-full-window {
    top: 0px;
    left: 0px;
    width: 100% !important;
    height: auto !important;
  }
}
@media only screen and (min-device-width: 668px) and (orientation: landscape) {
  .video-js-canvas.vjs-full-window {
    top: auto;
    bottom: auto;
    left: 0px;
    width: 100% !important;
    height: auto !important;
  }
}

video::-webkit-media-controls,
video::-webkit-media-controls-enclosure,
video::-webkit-media-controls-panel,
video::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

.vjs-fullscreen-control,
.vjs-progress-control,
.vjs-time-controls,
.vjs-time-divider {
  display: none;
}
.vjs-default-skin .vjs-play-control {
  color: #fff;
  position: absolute;
  left: 43%;
  z-index: 10000;
}
.vjs-default-skin .vjs-play-control:before {
  font-size: 3em !important;
  line-height: 1 !important;
}
