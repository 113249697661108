// Example markup usage, in HAML:
// %.dynamic-row
//   %.dynamic-row-item-1-of-2
//   %.dynamic-row-item-1-of-last
// %.dynamic-row
//   %.dynamic-row-item-1-of-3
//   %.dynamic-row-item-1-of-3
//   %.dynamic-row-item-1-of-last
#dynamic-row-layout {
  .core(@maxColumns, @itemNamePrefix, @rowName) {
    .one-of-X(@index) when (@index > 0) {
      .@{itemNamePrefix}-@{index} {
        .one-of(@index);
      }

      .one-of-X(@index - 1);
    }
    .one-of-X (0) {
    }

    .one-of(@n) {
      @val: (100% / @n);
      width: @val;
    }

    .makeRow() {
      .@{rowName} {
        display: block;
        clear: left;
        .clearfix();
      }
    }

    // Create one-of-X classes and row-of-X class
    .one-of-X(@maxColumns);
    .makeRow();

    [class*='@{itemNamePrefix}-'] {
      display: block;
      float: left;
    }

    .@{itemNamePrefix}-last {
      float: none;
      overflow: visible;
      display: flex;
      flex-grow: 1;

      // using flexbox, we need to ensure our buttons are
      // full width in their parent container
      > * {
        width: 100% !important;
      }

      // [GRYP-7667] when a user has specified the `width` option
      // we need to account for the last column and not set the width
      // to be 100% with flexbox (instead relying on the specified width value)
      // [GRYP-7968] when using the 'columns' option, need to style the last
      // column without 100% flexbox
      .user-defined-width &,
      .colorpicker-response-list-columns & {
        overflow: hidden;
        display: block;

        > * {
          width: inherit !important;
        }
      }
    }
  }
}

// Generate default class names
@dynamicRowName: dynamic-row;
@dynamicRowItemPrefix: dynamic-row-item-1-of;
#dynamic-row-layout > .core(
  @maxColumns: 21;
  @itemNamePrefix: @dynamicRowItemPrefix;
  @rowName: @dynamicRowName;
);;