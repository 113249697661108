/**
 * The grid-row-X classes are modifiers of the grid-row class.  The X refers
 * to the number of response options (or category options, in the transposed
 * case) in the grid, and determine the percentage width that descendant th
 * elements (i.e., headers for each column) should have.
 *
 * The @totalPercentage parameter determines the percentage to allocate
 * between the columns. This is usually 100% minus the width allocated for row
 * labels. 
 */
.make-grid-row-X(@n, @totalPercentage) when (@n > 0) {
  .grid-row-@{n} th {
    width: (@totalPercentage / @n);
  }

  .make-grid-row-X(@n - 1, @totalPercentage);
}

.make-grid-row-X(0, @totalPercentage) {
}

@rowLabelWidth: (100% / 3);
@rowLabelWidthSplitlabels: (@rowLabelWidth / 2);

.grid-container {
  overflow-x: auto;
  font-size: 14px;
  font-weight: 500;

  // Work around obscure iOS(7?) bug where a horizontally-scrolled child in a
  // vertically-scrolled parent triggers incorrectly positioned :hover effects.
  -webkit-overflow-scrolling: touch;

  @media (@mediaSkinny) {
    &.collapsible {
      overflow-x: visible;
    }
  }

  // [GRYP-8442] Enables sticky table headers for Grid columns
  &-sticky-header {
    overflow: auto;

    table.grid-layout {
      border-collapse: separate;
      position: relative;
    }

    .grid-layout thead th,
    .grid-layout thead td {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      background-color: var(--color-white);
      border-bottom: 1px solid var(--color-stonegrey-300);
      z-index: 999 !important;
    }

    .grid-layout tbody tr:first-child {
      th,
      td {
        border-top: 0;
      }
    }
  }
}

// Bootstrap's table styles tend to have a bit more specificity than normal,
// due to selectors like `.table td`. To override, we'll have to at least match
// that specificity, hence the (usually) undesirable nesting here.
// That said, because the markup inside tables is well-defined and standard,
// it makes sense to do such nesting.
.grid-layout {
  &:extend(.table all);
  &:extend(.table-hover all);
  &:extend(.table-condensed all);

  // Override bootstrap's bolding.
  th {
    font-weight: normal;
  }

  // Override bootstrap's left-alignment.
  th,
  td {
    text-align: center;
    padding: @spaceSM @spaceXS;
    border-top: 1px solid var(--color-stonegrey-300);
  }

  // Bootstrap doesn't distinguish between thead/tbody/tfooter cells; we do.
  thead {
    td,
    th {
      vertical-align: middle;
    }

    .grid-header-vertical-top {
      vertical-align: top;
    }

    .grid-header-vertical-bottom {
      vertical-align: bottom;
    }

    td {
      width: @rowLabelWidth;
    }

    // The total column doesn't need much space; it's just totals.
    .total {
      width: 10%;
    }

    th.error {
      background-color: #f2dede !important;
    }
  }

  // Override bootstrap's thick border between tbodys. Without this, all but
  // the first page of a displaymax grid have a thick top border.
  tbody + tbody {
    border-top-width: 0;
  }

  tbody {
    border-top: 1px solid var(--color-stonegrey-300);
    border-bottom: 1px solid var(--color-stonegrey-300);

    td,
    th {
      vertical-align: middle;
    }

    tr.error > th,
    tr.error > td,
    tr > td.error {
      background-color: #f2dede !important;
    }

    td {
      padding: 0 @spaceXS;
    }
  }

  // Override bootstrap's image scaling, which works inconsistently across
  // browsers in auto-layout tables.
  img {
    max-width: none;
  }

  // Nested inside .grid-layout to override bootstrap.
  .grid-item-text-left {
    text-align: left;
    font-weight: 500;
    color: var(--color-black);
    font-size: 1em;
    z-index: 10;
  }

  .grid-item-text-middle {
    text-align: center;
    font-weight: normal;
    color: var(--color-stonegrey-1200);
    font-size: 1em;
  }

  .grid-item-text-right {
    text-align: right;
    font-weight: normal;
    color: var(--color-stonegrey-1200);
    font-size: 1em;

    &.total {
      text-align: center;
    }
  }

  tr.grid-row {
    th {
      font-weight: 500;
      color: var(--color-black);
      z-index: 10;
    }
  }

  tr {
    th.grid-item-text-middle,
    th.grid-item-text-right {
      font-weight: 500;
      color: var(--color-black);
      z-index: 10;
    }
  }

  // Override bootstrap's hovering
  tbody tr:hover > td,
  tbody tr:hover > th {
    background-color: @tableBackgroundCellHover;
  }

  tbody tr:hover > td:hover {
    background-color: var(--color-stonegrey-400);
  }
}

.grid-layout-striped {
  &:extend(.table-striped all);

  tbody > tr:nth-child(odd) > td,
  tbody > tr:nth-child(odd) > th {
    background-color: var(--color-stonegrey-100);
  }

  tbody tr:hover > td,
  tbody tr:hover > th {
    background-color: @tableBackgroundCellHover;
  }
}

.grid-layout-splitlabels {
  thead td {
    width: @rowLabelWidthSplitlabels;
  }

  tbody .labels {
    display: none;
  }

  @media (@mediaSkinny) {
    thead td {
      display: none;
    }

    thead th[class^='grid-header-response-'] {
      font-weight: 500;
      padding-bottom: @spaceXS;
    }

    tbody tr th {
      display: none;
    }

    .labels {
      td[colspan] {
        border: none;
        padding-top: 0px;
        background: none;
      }

      table {
        width: 100%;
      }

      tbody tr th {
        display: table-cell;
        border: none;
        background: transparent;
      }
    }

    tbody .labels {
      display: table-row;

      th[class^='grid-item-text-'] {
        font-size: 0.875em;
        padding-top: @spaceXS;
      }

      .grid-item-text-left {
        padding-left: 0;
        text-align: left;
      }

      .grid-item-text-right {
        padding-right: 0;
        text-align: right;
      }
    }
  }
}

.grid-item {
}

.grid-category-item {
}

.grid-row {
}

input[type='number'].grid-input-error,
input[type='number'].grid-input-error:focus {
  border-color: #e9322d;
  color: #b94a48;
  -webkit-box-shadow: 0 0 6px #f8b9b7;
  -moz-box-shadow: 0 0 6px #f8b9b7;
  box-shadow: 0 0 6px #f8b9b7;
}

// We don't care if there are more than 12 columns: we can't reasonably
// equalise their widths, and that's an unreasonably large grid anyway.
.make-grid-row-X(12, (100% - @rowLabelWidth));

.grid-cell {
  overflow: hidden;

  @media (max-width: @breakpoint-sm) {
    &:not(:last-child) {
      border-bottom: 1px solid var(--color-stonegrey-300);
    }
  }

  // Again, dislike nesting, but: .grid-cells will always contain inputs,
  // and those inputs will always have labels that are used to enlarge the
  // hit box of the input. So assumption of use of a label element here is
  // reasonable.
  label {
    // Large padding and negative margin on labels makes whole grid cell
    // clickable.
    // @s: 200px;
    // padding: @s;
    // The 5px difference on the bottom accounts for/negates bootstrap's
    // 5px bottom margin on labels. Makes sure inputs are middle centred.
    // margin: (-@s) (-@s) (5px - @s) (-@s);

    // Remove flash of large element size on webkit browsers
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    padding: @spaceSM @spaceM;
    margin-bottom: 0;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 32px);

    div.uniform-checker,
    div.uniform-radio {
      margin-left: 0;
      margin-right: 0;

      > span {
        background-color: #fff;
      }
    }

    .label-inner {
      margin-left: @spaceSM;
    }

    @media (max-width: @breakpoint-sm) {
      > div.input-wrapper {
        display: flex;
        padding-left: @spaceM;
        padding-right: @spaceM;
      }
    }

    // disable any hover on mobile
    @media (max-width: @breakpoint-md) {
      &:hover {
        background: none;
      }

      &.focused {
        background-color: @tableBackgroundCellHover;
      }

      &.selected:not(.hidden-response) {
        background-color: var(--color-stonegrey-300);
      }
    }
  }

  // Ensure text inputs are centred, like radio and checkboxes, which
  // already have this style applied by bootstrap.
  input[type='text'],
  input[type='number'] {
    margin-top: 4px;
    height: 26px;
    padding: 5px 10px 7px;

    @media (max-width: @breakpoint-md) {
      width: 100%;
      max-width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 14px;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 18px;
    }

    &.hidden-response {
      color: #fff;
    }
  }

  @media (max-width: @breakpoint-sm) {
    .response-label.selected {
      .label-inner {
        font-weight: 600;
      }
    }

    .label-inner {
      font-size: 14px;
      font-weight: normal;

      .label-text {
        vertical-align: middle;
      }
    }

    .label-inner-input-text {
      padding-left: 17px;
      padding-bottom: 0;
    }
  }
}

@media (max-width: @breakpoint-md) {
  td.grid-cell {
    input[type='text'] {
      max-width: 80%;
    }
  }
}

.error .grid-cell {
  label {
    &:hover {
      background-color: #ebcccc;
    }
  }
}

.accordion-heading {
  padding: 0;

  .content {
    flex: 1;
  }

  .toggle {
    flex: 1;
    text-align: right;
  }
}

.accordion-inner {
  padding: 0;

  ul {
    margin-bottom: 0;
  }
}

.accordion-caret {
  &-expanded {
    .fa-chevron-down {
      display: none;
    }
  }

  &-collapsed {
    .fa-chevron-up {
      display: none;
    }
  }

  .accordion-toggle {
    color: var(--color-stonegrey-1200);
    font-weight: normal;
    display: flex;
    padding: @spaceSM @spaceM;

    &:hover {
      text-decoration: none;

      > .accordion-category-text {
        text-decoration: underline;
      }
    }

    .fa-check {
      margin-right: @spaceS;
    }
  }
}

.accordion-group {
  margin-bottom: 18px;
  border-color: var(--color-stonegrey-1200);
  .border-radius(2px);

  &:not(.selected) {
    &:not(.accordion-caret-expanded):not(.accordion-group-error) {
      border-color: var(--color-stonegrey-900);
    }

    .fa-check {
      display: none;
    }
  }
}

.accordion-group.accordion-caret-expanded {
  .box-shadow(0 3px 8px 0 rgba(67, 60, 84, 0.25));
}

.accordion-group-error {
  border-color: var(--color-grapefruit-900);

  .accordion-toggle {
    background-color: @errorBackground;
    .toggle {
      color: @errorText;
    }
  }
}

// Should override zebra striping, row-hover, but not cell-hover. Use of
// !important is a hack, but is *far* simpler than playing specificity games
// with bootstrap.
.grid-cell-distinct,
.grid-header-distinct {
  background-color: @tableBackgroundDistinct !important;
}

.hidden-check {
  display: none;
}
