@footerMarginBottom: @xl-gap;
@footerHeight: 34px;
@contentBottomPadding: 30px;

.html-sticky-footer() {
  @media (@mediaSkinny) {
    min-height: -webkit-fill-available;
  }
}

.body-sticky-footer() {
  @media (@mediaSkinny) {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    position: relative;
  }
}

.footer-static() {
  @media (@mediaSkinny) {
    position: static;
  }
}
